<template>
  <div class="h-full w-full flex flex-col bg-violet-50">
    <div
      class="flex flex-col-reverse items-center gap-6 w-full no-scrollbar overflow-y-scroll px-1 py-6 pb-6 pt-16 lg:ml-2 lg:m-0"
    >
      <template v-for="message in messages" :key="message.id">
        <timeline-tile :message="message"></timeline-tile>
      </template>
      <div
        v-if="!messages?.length && group?.type === 'paid-group'"
        class="max-w-screen-sm mx-1 text-center rounded-xl bg-violet-100 px-3 py-2 text-gray-500"
      >
        <p>Thanks for your purchase!</p>
        <p>Send me a message and I'll reply as soon as I can 😊</p>
      </div>
    </div>

    <div
      v-if="group?.type === 'paid-group'"
      class="absolute top-0 mt-16 w-full flex flex-col items-center justify-center p-2 z-50"
    >
      <div class="w-full flex justify-center text-white m-2">
        <template v-if="group?.timeRemaining > 0">
          <div
            class="flex justify-center items-center shadow-lg shadow-black/50 bg-primary rounded-full overflow-hidden"
          >
            <div
              class="text-xs font-medium pl-2 mr-2 py-1 h-full flex items-center"
            >
              Time Remaining:
            </div>
            <div class="font-bold text-sm pr-3 py-1">{{ timeRemaining }}</div>
          </div>
        </template>
        <template v-else>
          <div
            class="flex justify-center items-center shadow-lg shadow-black/50 bg-danger rounded-full overflow-hidden"
          >
            <div
              class="text-xs font-medium pl-2 mr-2 py-1 h-full flex items-center"
            >
              No Time Remaining
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { getTimeString } from "@/utilities/time";
import TimelineTile from "./TimelineTile";
const store = useStore();

// eslint-disable-next-line no-unused-vars
const group = computed(() => store.state.firebaseData.selectedGroup);
const messages = computed(() => store.state.firebaseData.selectedGroupMessages);

const timeRemaining = computed(() => {
  return getTimeString(group.value.timeRemaining);
});
</script>
