<template>
  <ion-page>
    <ion-header class="flex justify-center border-b">
      <ion-toolbar class="flex items-center lg:max-w-screen-sm bg-transparent">
        <ion-title>Earn</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="w-full h-full flex items-center justify-center">
        <ion-spinner
          v-if="isLoading"
          name="crescent"
          color="primary"
          class="h-24 w-24"
        ></ion-spinner>
      </div>
      <ion-router-outlet :animated="isLargeScreen"></ion-router-outlet>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { computed, ref } from "vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSpinner,
  IonRouterOutlet,
  onIonViewDidEnter,
  onIonViewDidLeave,
  useIonRouter,
} from "@ionic/vue";
import { getChecklistStatus } from "@/utilities/zuluMe";
import { useRoute } from "vue-router";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
const breakpoints = useBreakpoints(breakpointsTailwind);
const isLargeScreen = breakpoints.greaterOrEqual("lg");

const route = useRoute();
const router = useIonRouter();

const isLoading = ref(false);

// Subscription
const isSetupComplete = computed(
  () => status.value && Object.values(status.value)?.every((e) => e)
);

// Dynamic page routing (checks if setup is complete for user)
const status = ref();
onIonViewDidEnter(async () => {
  if (route.name === "earn") {
    isLoading.value = true;
    status.value = await getChecklistStatus();
    isLoading.value = false;
  }

  // Redirect to appropriate page if tab page is still visible
  // (i.e. user hasn't navigated away to different tab, in case of slow connection)
  if (isPageVisible.value) {
    router.replace({
      name: isSetupComplete.value ? "earn-configure" : "earn-setup-checklist",
    });
  }
});

// Page Visible (to help with dynamic tab routing)
const isPageVisible = ref(false);
onIonViewDidEnter(() => (isPageVisible.value = true));
onIonViewDidLeave(() => (isPageVisible.value = false));
</script>
