import { db } from "../plugins/firebase";
import { firestoreAction } from "@xquick-code/vuexfire";

export default {
  namespaced: true,
  state() {
    return {
      selectedPackage: null,
    };
  },
  getters: {},
  actions: {
    bindSelectedPackage: firestoreAction(
      async ({ bindFirestoreRef, rootState }) => {
        console.log("packageId", rootState.route.query?.packageId);
        if (rootState.route.query?.packageId) {
          return bindFirestoreRef(
            "selectedPackage",
            db.collection("packages").doc(rootState.route.query?.packageId)
          );
        }
      }
    ),
  },
};
