<template>
  <div>
    <!-- Audio Button -->
    <button
      class="cursor-pointer rounded-full h-10 w-10 flex hover:bg-background-300 justify-center items-center gap-2 text-gray-400 hover:text-gray-500 font-bold text-lg"
      @click="setOpenCompose(true)"
    >
      <ion-icon slot="icon-only" class="h-6 w-6" :icon="mic"></ion-icon>
    </button>
    <!-- Modal -->
    <ion-modal
      ref="composeModal"
      @ion-modal-did-dismiss="setOpenCompose(false)"
    >
      <ion-page id="composeWindow">
        <ion-content fullscreen>
          <div
            class="relative w-full h-full bg-black overflow-hidden flex justify-center items-center"
          >
            <template v-if="!recordingUrl">
              <div
                v-if="stream"
                class="flex justify-center items-center w-full h-full bg-secondary"
              >
                <AVMedia
                  :media="stream"
                  type="frequ"
                  frequ-direction="mo"
                  :frequ-lnum="60"
                  line-color="#fff"
                />
              </div>
              <!-- fallback spinner -->
              <div
                class="flex flex-col items-center justify-center"
                v-else-if="isLoadingStream"
              >
                <ion-spinner
                  name="crescent"
                  color="light"
                  class="h-24 w-24"
                ></ion-spinner>
                <div class="text-white font-bold text-xl mt-4">
                  Starting Microphone
                </div>
              </div>
              <div v-else>
                <ion-button @click="startCameraStream()"
                  >Wake Microphone</ion-button
                >
              </div>

              <!-- Controls -->
              <div
                v-if="stream"
                class="absolute w-full h-full flex flex-col justify-between"
              >
                <!-- Header -->
                <div></div>

                <!-- Footer -->
                <div class="flex justify-center items-center p-4 gap-3">
                  <div class="w-12"></div>
                  <button
                    class="w-16 h-16 rounded-full border-solid border-white border-4 p-1"
                    :class="{ 'p-3': isRecording }"
                    aria-label="record"
                    @click="toggleRecording()"
                  >
                    <div
                      class="w-full h-full bg-danger"
                      :class="{
                        'rounded-full': !isRecording,
                        rounded: isRecording,
                      }"
                    ></div>
                  </button>
                  <DeviceSelection
                    :disabled="isRecording"
                    audio
                    @change="handleDeviceChange"
                  >
                    <ion-icon class="text-2xl" color="light" :icon="settings">
                    </ion-icon>
                  </DeviceSelection>
                </div>
              </div>
            </template>

            <template v-else>
              <div class="relative flex flex-col w-full h-full bg-black">
                <div class="w-full h-full rounded-b-xl">
                  <AudioPlayer
                    :fill="true"
                    class="w-full h-full overflow-hidden rounded-b-xl"
                    :source="recordingUrl"
                    loop
                    autoplay
                  />
                </div>
                <div
                  class="absolute w-full h-full bg-black/75 backdrop-blur-lg flex flex-col items-center justify-center"
                  v-if="isSending"
                  @click.stop
                >
                  <CircleProgress
                    :percent="uploadProgress"
                    :is-gradient="true"
                    :size="120"
                    :gradient="{
                      angle: 90,
                      startColor: 'var(--ion-color-primary)',
                      stopColor: 'var(--ion-color-secondary)',
                    }"
                    >{{ uploadProgress }}</CircleProgress
                  >
                  <div class="text-white font-bold text-xl mt-4">Uploading</div>
                </div>
                <div class="w-full flex justify-between p-3 pt-4">
                  <button
                    class="rounded-lg text-white bg-gray-900 font-semibold py-2 px-4 flex items-center gap-1"
                    @click="handleRedo()"
                  >
                    <ion-icon :icon="refresh" />

                    Redo
                  </button>
                  <button
                    class="rounded-lg bg-gradient-to-r from-primary to-secondary shadow-lg shadow-primary text-white text-lg font-bold py-2 px-4 flex items-center gap-2"
                    @click="handleSendMessage()"
                  >
                    Send
                    <ion-icon :icon="send" />
                  </button>
                </div>
              </div>
            </template>

            <!-- Close Button -->
            <div class="absolute top-0 left-0 safe-t p-2">
              <button @click="setOpenCompose(false)">
                <ion-icon
                  :icon="closeOutline"
                  class="text-2xl text-white backdrop-blur-lg bg-black/50 hover:bg-black/40 rounded-full p-1"
                ></ion-icon>
              </button>
            </div>
          </div>
        </ion-content>
      </ion-page>
    </ion-modal>
  </div>
</template>

<script setup>
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import { AVMedia } from "vue-audio-visual";
import AudioPlayer from "@/components/AudioPlayer";
import DeviceSelection from "./DeviceSelection";

import { ref } from "vue";
import {
  IonSpinner,
  IonModal,
  IonContent,
  IonPage,
  IonIcon,
  IonButton,
} from "@ionic/vue";
import { closeOutline, mic, send, refresh, settings } from "ionicons/icons";
import {
  startAudioStream,
  resetRecorder,
  stream,
  isRecording,
  isLoadingStream,
  recordingUrl,
  recordedStream,
  startRecording,
  stopRecording,
  cleanupRecording,
  duration,
} from "./audioRecorder";
import { db, storage } from "@/plugins/firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import { useStore } from "vuex";

const store = useStore();

// Record
const toggleRecording = () => {
  if (!isRecording.value) {
    startRecording(stream);
  } else {
    stopRecording(stream);
  }
};

// Send
const isSending = ref();
const uploadProgress = ref();
const handleSendMessage = async () => {
  isSending.value = true;

  try {
    const selectedGroup = store.state.firebaseData.selectedGroup;
    // Get new message doc
    const messagesCollectionRef = db
      .collection("groups")
      .doc(selectedGroup.id)
      .collection("messages");
    const newMessage = messagesCollectionRef.doc();

    // Upload video
    let upload = recordedStream.value;
    const uploadDestination = `content/${newMessage.id}`;
    const uploadFileTask = storage
      .child(`${uploadDestination}/upload`)
      .put(upload);
    // Track progress updates
    uploadFileTask.on("state_changed", (snapshot) => {
      uploadProgress.value =
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    });
    await uploadFileTask;

    // Create message
    await newMessage.set({
      content: `${uploadDestination}/upload`,
      group: db.collection("groups").doc(selectedGroup.id),
      contentDuration: duration.value,
      sentAt: firebase.firestore.FieldValue.serverTimestamp(),
      sentBy: db
        .collection("users")
        .doc(store.state.firebaseData.currentUser.id), // TODO: remove hardcoded single user id
      status: "needs conversion",
      type: "audio",
    });
  } catch (error) {
    console.error(error);
  }
  isSending.value = false;
  setOpenCompose(false);
};

// Redo
const handleRedo = () => {
  cleanupRecording();
};

// Device Change
const handleDeviceChange = async () => {
  await cleanupRecording();
  await startAudioStream();
};

const composeModal = ref(null);
const setOpenCompose = async (value) => {
  value
    ? composeModal.value?.$el?.present()
    : composeModal.value?.$el?.dismiss();
  if (value) {
    // Get stream
    await startAudioStream();
  } else {
    // Cleanup stream
    await resetRecorder();
  }
};
</script>

<style scoped>
.reflected {
  transform: scale(-1, 1);
}
</style>
