<template>
  <div class="w-full flex justify-center">
    <div
      class="w-full max-w-screen-sm flex justify-center items-center p-2 gap-3"
      :class="{
        'grayscale pointer-events-none opacity-50':
          group?.type === 'paid-group' &&
          group?.timeRemaining === 0 &&
          group?.createdBy?.id !== currentUser?.id,
      }"
    >
      <div class="w-full">
        <TextMessage />
      </div>

      <div>
        <CameraMessage />
      </div>

      <div class="flex gap-2 w-full">
        <div>
          <AudioMessage />
        </div>
        <div v-if="isSupportedScreenShare">
          <ScreenMessage />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CameraMessage from "./timeline/composers/CameraMessage";
import TextMessage from "./timeline/composers/TextMessage";
import AudioMessage from "./timeline/composers/AudioMessage";
import ScreenMessage from "./timeline/composers/ScreenMessage";
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const isSupportedScreenShare = "getDisplayMedia" in navigator.mediaDevices;

// eslint-disable-next-line no-unused-vars
const group = computed(() => store.state.firebaseData.selectedGroup);
const currentUser = computed(() => store.state.firebaseData.currentUser);
</script>

<style>
#composeWindow ion-buttons ion-button::part(native) {
  color: white;
}

#textModal .textarea-legacy-wrapper {
  height: 100%;
}
</style>
