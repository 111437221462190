<template>
  <div
    @click="setOpenOnboardingMessage(true)"
    @ion-modal-did-dismiss="setOpenOnboardingMessage(false)"
  >
    <slot></slot>
    <ion-modal ref="onboardingMessageModal" id="onboardingMessageModal">
      <ion-loading :is-open="isLoading"></ion-loading>
      <ion-page>
        <ion-header class="flex justify-center border-b">
          <ion-toolbar
            class="flex items-center lg:max-w-screen-sm bg-transparent"
          >
            <ion-buttons slot="start">
              <ion-button @click="setOpenOnboardingMessage(false)"
                >Cancel</ion-button
              >
            </ion-buttons>
            <ion-title>{{ isEdit ? "Edit" : "Create" }} Message</ion-title>
          </ion-toolbar>
        </ion-header>
        <ion-content class="relative">
          <!-- Message Type -->
          <section class="p-2">
            <ion-segment
              :value="message.type"
              @ionChange="message.type = $event.target.value"
            >
              <ion-segment-button value="video">
                <ion-label>Video</ion-label>
              </ion-segment-button>
              <ion-segment-button value="audio">
                <ion-label>Audio</ion-label>
              </ion-segment-button>
              <ion-segment-button value="text">
                <ion-label>Text</ion-label>
              </ion-segment-button>
            </ion-segment>
          </section>
          <!-- Body -->

          <section class="mb-24">
            <ion-list inset>
              <ion-item>
                <ion-input
                  label="Title"
                  label-placement="stacked"
                  placeholder="Quick title that only you will see"
                  v-model="message.title"
                ></ion-input>
              </ion-item>
              <!-- Audio/Video upload -->
              <template v-if="isMediaMessage">
                <ion-item class="w-full">
                  <div class="flex flex-col w-full">
                    <div class="w-full flex justify-between items-center">
                      <ion-label class="flex items-center">
                        {{
                          message.type === "video" ? "Video file" : "Audio file"
                        }}
                      </ion-label>
                      <ion-button
                        slot="end"
                        fill="clear"
                        class="font-semibold"
                        @click="handleSelectFile()"
                      >
                        {{ previewURL ? "Change" : "Select" }}
                      </ion-button>
                    </div>
                    <div
                      v-if="previewURL"
                      class="w-full flex justify-center mb-4"
                      :class="{
                        'h-[350px]': message.type === 'video',
                        'h-[58px]': message.type === 'audio',
                      }"
                    >
                      <VideoPlayer
                        v-if="message.type === 'video'"
                        :source="previewURL"
                        fill
                        class="rounded-lg overflow-hidden"
                      ></VideoPlayer>
                      <AudioPlayer
                        v-if="message.type === 'audio'"
                        :source="previewURL"
                        compact
                        fill
                        class="rounded-full overflow-hidden"
                      ></AudioPlayer>
                    </div>
                  </div>
                </ion-item>
              </template>

              <template v-else-if="message.type === 'text'">
                <ion-item>
                  <ion-textarea
                    id="textInput"
                    label="Text Content"
                    label-placement="stacked"
                    placeholder="Start typing..."
                    auto-grow
                    class="min-h-72"
                    v-model="message.data"
                  ></ion-textarea>
                </ion-item>
              </template>
            </ion-list>

            <!-- Delete Message -->
            <div v-if="isEdit">
              <ion-button
                expand="block"
                fill="clear"
                size="small"
                color="danger"
                @click="handleDeleteMessage()"
                >Delete Message</ion-button
              >
            </div>
          </section>

          <!-- Message Actions -->
          <section
            class="w-full px-2 py-2 fixed bottom-0 bg-white/50 backdrop-blur"
          >
            <div>
              <div class="flex flex-col">
                <ion-button
                  :disabled="!isComplete"
                  expand="block"
                  @click="handleSaveMessage()"
                  >Save Message</ion-button
                >
              </div>
            </div>
          </section>
        </ion-content>
      </ion-page>
    </ion-modal>
  </div>
</template>

<script setup>
import VideoPlayer from "@/components/VideoPlayer";
import AudioPlayer from "@/components/AudioPlayer";
import {
  IonModal,
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonLabel,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonButtons,
  IonLoading,
} from "@ionic/vue";
import { reactive, ref, defineEmits, defineProps, computed } from "vue";
import { promptForFileSelection, getFileBlobURLs } from "@/utilities/file";
import { db, storage } from "@/plugins/firebase";
import { getTextTimeEstimate } from "@/utilities/time";
import firebase from "firebase/app";
import "firebase/firestore";

const props = defineProps({ offerId: String, messageId: String });
let message = reactive({});

const isEdit = computed(() => !!props.messageId);
const isMediaMessage = computed(() =>
  ["video", "audio"].includes(message.type)
);

const isComplete = computed(() => {
  return message?.data && message?.title;
});

const onboardingMessageModal = ref(null);
const setOpenOnboardingMessage = async (value) => {
  value
    ? onboardingMessageModal.value?.$el?.present()
    : onboardingMessageModal.value?.$el?.dismiss();
  if (value) {
    // Retrieve document data if edit mode
    if (isEdit.value) {
      const messageData = (
        await db
          .collection("packages")
          .doc(props.offerId)
          .collection("onboarding")
          .doc(props.messageId)
          .get()
      ).data();

      Object.assign(message, {
        type: messageData.type,
        data: messageData.content,
        duration: messageData.duration,
        title: messageData.title,
        createdAt: messageData.createdAt,
      });

      if (["video", "audio"].includes(messageData.type)) {
        message.data = " ";
        const messageVideoRef = storage.child(messageData.content);
        previewURL.value = await messageVideoRef?.getDownloadURL();
      }
    }
    // Otherwise default to blank video message
    else {
      // Reset all values (don't break reactivity)
      Object.keys(message).forEach((key) => delete message[key]);
      Object.assign(message, { type: "video" });
      previewURL.value = null;
    }
  }
};

const previewURL = ref();
const handleSelectFile = async () => {
  const files = await promptForFileSelection({ accept: `${message.type}/*` });
  if (!files.length) {
    return;
  }
  // Set data on message object
  message.data = files[0];

  // Get preview url
  const fileURLs = getFileBlobURLs(files);
  if (fileURLs.length) {
    previewURL.value = fileURLs[0];
  }
};
const getMediaDuration = async () => {
  return new Promise((resolve) => {
    // Create video element to get file duration
    var video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = function () {
      resolve(video.duration * 1000);
      video.remove();
    };
    video.src = previewURL.value;
  });
};

const isLoading = ref(false);
const handleSaveMessage = async () => {
  isLoading.value = true;

  const duration = isMediaMessage.value
    ? await getMediaDuration(previewURL.value)
    : getTextTimeEstimate(message.data);

  console.log("duration", duration, getTextTimeEstimate(message.data));

  const messageRef = db
    .collection("packages")
    .doc(props.offerId)
    .collection("onboarding")
    .doc(props.messageId);

  // Create doc
  await messageRef.set({
    title: message.title,
    type: message.type,
    createdAt:
      message.createdAt ?? firebase.firestore.FieldValue.serverTimestamp(),
  });

  // Upload file if video or audio (blob check is to make sure it is a changed media file when editing)
  if (isMediaMessage.value && message.data instanceof Blob) {
    const uploadDestination = `/packages/${props.offerId}/${messageRef.id}/upload`;
    await storage.child(uploadDestination).put(message.data);
    await messageRef.update({ content: uploadDestination, duration });
  } else if (!isMediaMessage.value) {
    await messageRef.update({ content: message.data, duration });
  }

  isLoading.value = false;
  emit("message-modified");
  setOpenOnboardingMessage(false);
};

const handleDeleteMessage = async () => {
  isLoading.value = true;

  await db
    .collection("packages")
    .doc(props.offerId)
    .collection("onboarding")
    .doc(props.messageId)
    .delete();

  isLoading.value = false;

  emit("message-modified");
  setOpenOnboardingMessage(false);
};

const emit = defineEmits("message-modified");
</script>

<style scoped>
.segment-button-checked {
  color: #fff;
}

.segment-button-checked::part(indicator-background) {
  background: var(--ion-color-primary);
}

@media (min-width: 768px) {
  #onboardingMessageModal {
    --width: 400px;
  }
}

ion-input,
ion-textarea {
  --placeholder-opacity: 0.2;
}
</style>

<style>
#onboardingMessageModal .native-textarea {
  min-height: 10rem;
}
</style>
