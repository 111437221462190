import { ref } from "vue";
import moment from "moment";

// Stream
let stream;
let screenStream;
let microphoneStream;
let useMicrophone = false;
export const mimeType = ref();
export const isLoadingStream = ref(false);

// Recording
let recorder;
let recordedChunks = [];
export const recordedStream = ref();
export const recordingUrl = ref();
export const isRecording = ref(false);
let startTime;
let endTime;
export const duration = ref();

// eslint-disable-next-line no-unused-vars
export const startScreenStream = async () => {
  try {
    isLoadingStream.value = true;

    // Get preferred device
    const audioDeviceId = localStorage.getItem("preferredAudioDeviceId");

    // Setup screen constraints
    const videoConfig = {
      width: {
        max: 1920,
      },
      height: {
        max: 1080,
      },
      frameRate: {
        ideal: 30,
      },
      displaySurface: ["window"],
    };

    // Screen Stream
    screenStream = await navigator.mediaDevices.getDisplayMedia({
      video: videoConfig,
      audio: true,
    });

    // Microphone Stream
    useMicrophone = getStoredMicrophonePreference();
    if (useMicrophone) {
      let audioConfig = true;
      if (audioDeviceId) {
        audioConfig = { deviceId: audioDeviceId };
      }
      microphoneStream = await navigator.mediaDevices.getUserMedia({
        audio: audioConfig,
      });
    }

    stream = await createMergedStream();
    // Stop Loading
    isLoadingStream.value = false;

    console.log("stream", stream.getAudioTracks());

    // Stop screen share handler
    stream.getVideoTracks()[0].onended = async () => {
      stopRecording();
    };

    let recordStartSound;

    try {
      // Start recording sound
      recordStartSound = new Audio("/assets/audio/screenRecorderStart.mp3");
      recordStartSound.volume = 0.1;
      recordStartSound.play();
    } catch (error) {
      console.log(error);
    }

    // Wait 3 seconds before starting
    await doCountdown(3);

    // Stop sounds
    recordStartSound.pause();

    // TODO: play recording start sound

    // Set recording prefix on tab title
    document.title = `(Recording) ${document.title}`;

    // Start Recording
    startRecording();
  } catch (error) {
    console.error(error);
  }
};

const createMergedStream = async () => {
  // Merge audio from screen stream and microphone stream
  const audioCtx = new AudioContext();
  const destination = audioCtx.createMediaStreamDestination();

  // Add microphone stream audio
  if (useMicrophone) {
    const source1 = audioCtx.createMediaStreamSource(microphoneStream);
    source1.connect(destination);
  }

  // Add screen stream audio if available
  if (screenStream.getAudioTracks()?.length) {
    const source2 = audioCtx.createMediaStreamSource(screenStream);
    source2.connect(destination);
  }

  const outputStream = new MediaStream();
  outputStream.addTrack(screenStream.getVideoTracks()[0]);
  if (destination.stream.getAudioTracks()?.length) {
    outputStream.addTrack(destination.stream.getAudioTracks()[0]);
  }

  return outputStream;
};

export const setInputDevice = (deviceId, kind) => {
  if (kind.includes("video")) {
    localStorage.setItem("preferredVideoDeviceId", deviceId);
  }
  if (kind.includes("audio")) {
    localStorage.setItem("preferredAudioDeviceId", deviceId);
  }
};

export const startRecording = () => {
  // Get supported mime type
  const types = [
    "video/x-matroska;codecs=h264",
    "video/webm;codecs=vp9,codecs=opus",
    "video/webm;codecs=vp9,opus",
    "video/webm;codecs=vp9",
    "video/webm;codecs=vp8,codecs=opus",
    "video/webm;codecs=vp8,opus",
    "video/webm;codecs=vp8",
    "video/mpeg",
    "video/mp4",
    "video/mpeg4",
  ];

  for (const type of types) {
    const isSupported = MediaRecorder.isTypeSupported(type);
    console.log(`${type} ${isSupported}`);
    if (isSupported) {
      mimeType.value = type;
      break;
    }
  }

  // Start recorder
  recorder = new MediaRecorder(stream, {
    mimeType: mimeType.value,
  });
  recorder.start(100);
  isRecording.value = true;

  recorder.ondataavailable = async (event) => {
    // record new chunck
    recordedChunks.push(await event.data);
  };

  startTime = new Date();
};

export const stopRecording = () => {
  console.log("stopping", stream);

  recorder.onstop = () => {
    document.title = document.title.replace("(Recording) ", "");
    isRecording.value = false;
    endTime = new Date();

    // Calculate duration
    const startTimeMoment = moment(startTime);
    const endTimeMoment = moment(endTime);
    duration.value = endTimeMoment.diff(startTimeMoment);

    recordedStream.value = new Blob(recordedChunks, {
      type: mimeType.value,
    });

    recordingUrl.value = window.URL.createObjectURL(recordedStream.value);

    console.log("recordingURL", recordingUrl.value);
    cleanupScreenStream();
  };

  console.log("recorded", recorder);

  // Stop recorder
  recorder.stop();
};

export const cleanupScreenStream = async () => {
  console.log("cleaning up screen stream", stream);
  try {
    stream.getAudioTracks().forEach((track) => {
      track.stop();
    });
  } catch (error) {
    console.error(error);
  }
  try {
    screenStream.getAudioTracks().forEach((track) => {
      track.stop();
    });
    microphoneStream?.getAudioTracks().forEach((track) => {
      track.stop();
    });
  } catch (error) {
    console.error(error);
  }
  try {
    stream.getVideoTracks().forEach((track) => {
      track.stop();
    });
  } catch (error) {
    console.error(error);
  }

  stream = null;
};

export const cleanupRecording = () => {
  recorder = null;
  recordedChunks = [];
  recordedStream.value = null;
  recordingUrl.value = null;
  mimeType.value = null;

  startTime = null;
  endTime = null;
  duration.value = null;
};

export const resetRecorder = async () => {
  await cleanupScreenStream();
  cleanupRecording();
};

export const countdown = ref(0);
const doCountdown = async (seconds, parentResolve) => {
  countdown.value = seconds;
  if (seconds !== 0) {
    await new Promise((resolve) =>
      setTimeout(() => doCountdown(seconds - 1, resolve), 1000)
    );
  }
  if (parentResolve) {
    parentResolve();
  }
  console.log("done");
};

// Use Microphone
const getStoredMicrophonePreference = () => {
  const useMicrophoneForScreen = localStorage.getItem("useMicrophoneForScreen");
  if (useMicrophoneForScreen) {
    return useMicrophoneForScreen === "true";
  }
  // Default to true if not selected yet by user
  return true;
};
