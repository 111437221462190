<template>
  <ion-item-sliding
    ref="itemRef"
    :class="{
      selected:
        selectedGroup &&
        route.params.groupId === group.id &&
        (isPlatform('desktop') || isPlatform('tablet')),
    }"
    class="rounded-xl mb-2"
  >
    <ion-item>
      <div slot="start" class="flex h-full items-center">
        <div
          v-if="unreadMessages?.length"
          class="absolute left-0 bg-primary h-3 w-3 font-bold inline-block rounded-full mx-2 text-white"
        ></div>
        <AvatarGroup
          class="flex-none hover:opacity-75 transition"
          :users="group.members"
          :display-limit="2"
          type="square"
          size="medium"
        />
      </div>
      <div
        class="flex flex-col w-full py-1 lg:py-2 min-h-[75px] cursor-pointer"
        @click.stop="selectGroup(group.id)"
      >
        <!-- First Row -->

        <div class="flex w-full justify-between items-center min-w-0 text-base">
          <!-- Group Name -->
          <div
            class="flex w-full flex-shrink-1 truncate items-center mr-2 font-semibold text-lg"
          >
            {{ memberNameList ? memberNameList : "Me" }}
          </div>

          <!-- Last Message time and -->
          <div
            class="flex justify-end items-center gap-1 font-medium flex-shrink-0 opacity-60 text-sm"
          >
            <template v-if="group.latestMessageSentAt">
              {{
                moment(group.latestMessageSentAt?.toDate()).calendar({
                  lastDay: "[Yesterday]",
                  sameDay: "LT",
                  lastWeek: "dddd",
                  sameElse: "L",
                })
              }}
            </template>
            <!-- <ion-icon
              v-if="isPlatform('mobile') && !isPlatform('tablet')"
              class=""
              :icon="chevronForward"
            ></ion-icon> -->
          </div>
        </div>
        <div
          v-if="
            group.latestMessageSentAt && group.latestMessage.sentBy?.displayName
          "
          class="text-sm opacity-60 font-medium truncate"
        >
          {{ group.latestMessage.sentBy?.displayName }}:
          <span class="capitalize opacity-60"
            >{{
              ["video", "audio"].includes(group.latestMessage.type)
                ? group.latestMessage.type
                : group.latestMessage.content
            }}

            <template
              v-if="['video', 'audio'].includes(group.latestMessage.type)"
            >
              ({{ getTimeString(moment(group.latestMessage.contentDuration)) }})
            </template>
          </span>
        </div>

        <!-- Group Information -->
        <div
          v-if="group.type === 'paid-group' && isCreatedByCurrentUser"
          class="flex lg:flex-col text-sm"
        >
          <!-- Paid Group info -->
          <div
            v-if="group.type === 'paid-group' && isCreatedByCurrentUser"
            class="flex gap-1 flex-wrap my-1"
          >
            <FluidChip
              class="text-[.6rem] font-bold h-5 px-2"
              :class="{
                'bg-green-200 text-green-600': props.group.timeRemaining > 0,
                'bg-red-200 text-red-600': props.group.timeRemaining === 0,
              }"
            >
              <font-awesome-icon class="" icon="fa-solid fa-stopwatch" />
              <div class="ml-1">
                {{ timeRemaining }}
              </div>
            </FluidChip>

            <FluidChip
              class="bg-black/5 text-[.6rem] text-black/50 font-bold h-5 px-2"
            >
              <font-awesome-icon class="" icon="fa-solid fa-calendar-days" />
              <div class="ml-1 shrink-0">
                {{ moment(group.createdAt.toDate()).fromNow() }}
              </div>
            </FluidChip>
          </div>
        </div>
      </div>

      <div class="flex items-center cursor-pointer" slot="end">
        <ion-icon :icon="ellipsisVertical" @click="openItem()"></ion-icon>
      </div>
    </ion-item>
    <ion-item-options side="end">
      <ion-item-option
        v-if="group.members?.length >= 1"
        color="danger"
        expandable
        @click="handleDelete()"
      >
        <ion-icon :icon="trash"></ion-icon>

        <ion-alert
          :is-open="isOpenDeleteAlert"
          header="Delete Group"
          sub-header="Are you sure you want to delete this group? Other participants will still be able to view the group."
          :buttons="[
            { text: 'OK', handler: confirmDelete, role: 'confirm' },
            { text: 'Cancel', role: 'cancel' },
          ]"
          @didDismiss="isOpenDeleteAlert = false"
        ></ion-alert>
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import AvatarGroup from "@/components/AvatarGroup";
import FluidChip from "@/components/FluidChip";
import moment from "moment";
import { db } from "@/plugins/firebase";
import { computed, defineProps, onMounted, ref } from "vue";
import {
  useIonRouter,
  isPlatform,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonItem,
  IonIcon,
  IonAlert,
} from "@ionic/vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { getTimeString } from "@/utilities/time";
import { chevronForward, trash, ellipsisVertical } from "ionicons/icons";

// Props
const props = defineProps({
  group: Object,
});

const itemRef = ref(null);
const openItem = () => {
  itemRef.value.$el.open();
  setTimeout(() => {
    itemRef.value.$el.close();
  }, 3000);
};

// Router
const router = useIonRouter();
// Route
const route = useRoute();
// Store
const store = useStore();

const currentUser = computed(() => store.state.firebaseData.currentUser);

const memberNameList = computed(() => {
  if (
    store.state.firebaseData.groupsReady &&
    !props.group.members.some((e) => !e?.id)
  ) {
    return props.group.members
      .filter((e) => e.id !== currentUser.value?.id)
      ?.map((e) => {
        return e.displayName ? e.displayName : `${e.firstName} ${e.lastName}`;
      })
      ?.join(", ");
  }
  return "";
});

// Deletion Action
const isOpenDeleteAlert = ref(false);
const handleDelete = () => {
  isOpenDeleteAlert.value = true;
};
const confirmDelete = async (test) => {
  const adjustedMembers = props.group?.members
    ?.filter((e) => e.id !== currentUser.value.id)
    .map((e) => db.collection("users").doc(e.id));

  console.log("adjustedMembers", adjustedMembers);
  const result = await db
    .collection("groups")
    .doc(props.group.id)
    .update({ members: adjustedMembers });

  router.navigate({ name: "groups" }, "root", "replace");
  return true;
};

// Unread message
const unreadMessages = computed(() =>
  store.state.firebaseData.unreadMessageNotifications.filter(
    (e) => e.group?.id === props.group.id
  )
);

// Time Remaining
const timeRemaining = computed(() => {
  return props.group.timeRemaining
    ? getTimeString(moment(props.group.timeRemaining))
    : "0:00";
});

const isCreatedByCurrentUser = computed(() => {
  if (store.state.firebaseData.groupsReady && props.group.createdBy) {
    return (
      props.group.createdBy.id === currentUser.value.id ||
      (typeof props.group.createdBy === "string" &&
        props.group.createdBy?.replace("users/", "") === currentUser.value.id)
    );
  }
  return false;
});

// Group selection
const selectedGroup = computed(() => {
  return store.state.firebaseData.selectedGroup;
});
const selectGroup = () => {
  const groupId = props.group?.id;
  // replace current state if already on group page to avoid transition effect
  if (route.params?.groupId) {
    console.log("test");
    router.navigate({ name: "group", params: { groupId } }, "root", "replace");
  }
  // otherwise push group page for transition effect
  else {
    router.navigate({ name: "group", params: { groupId } }, "forward", "push");
  }
};
</script>

<style scoped>
ion-item::part(native) {
  @apply bg-violet-50;
}

.selected ion-item::part(native) {
  @apply bg-violet-200;
}
</style>
