<template>
  <ion-page>
    <ion-header class="flex justify-center border-b">
      <ion-toolbar class="flex items-center lg:max-w-screen-sm bg-transparent">
        <ion-buttons slot="start">
          <ion-back-button default-href="/earn/page/edit"></ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button @click="handleSave()">Save</ion-button>
        </ion-buttons>
        <ion-title>Edit Bio</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="flex justify-center">
        <div
          class="max-w-screen-sm w-full flex flex-col items-center gap-8 lg:p-4"
        >
          <ion-spinner v-if="isLoading"></ion-spinner>

          <div
            class="w-full min-h-screen lg:min-h-[20rem] flex flex-col gap-1"
            :class="{ 'opacity-25 pointer-events-none': isLoading }"
          >
            <QuillEditor
              v-model:content="description"
              theme="bubble"
              contentType="html"
              placeholder="Tell us about yourself, your business, anything!"
              class="lg:bg-gray-100 lg:rounded-xl"
            ></QuillEditor>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonBackButton,
  IonButtons,
  IonButton,
  IonSpinner,
  onIonViewWillEnter,
  useIonRouter,
} from "@ionic/vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.bubble.css";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { db } from "@/plugins/firebase";
const isLoading = ref(false);
const store = useStore();

const router = useIonRouter();

const currentUser = computed(() => store.state.firebaseData.currentUser);
const currentUserMeProfile = computed(
  () => store.state.firebaseData.currentUserMeProfile
);

const description = ref("");

const handleSave = async () => {
  isLoading.value = true;
  db.collection("users")
    .doc(currentUser.value.id)
    .collection("pages")
    .doc("profile")
    .update({ description: description.value });
  isLoading.value = false;

  // Go back to profile edit page
  if (router.canGoBack()) {
    router.back();
  } else {
    router.navigate(
      {
        name: "earn-edit-page",
      },
      "back",
      "pop"
    );
  }
};

onIonViewWillEnter(() => {
  isLoading.value = true;

  description.value = currentUserMeProfile.value?.description;

  isLoading.value = false;
});
</script>

<style scoped>
ion-toolbar {
  --background: transparent;
}
</style>
