<template>
  <ion-page>
    <ion-content :fullscreen="true" class="relative">
      <div class="flex flex-col h-full w-full bg-white">
        <header>
          <nav class="bg-white border border-solid border-gray-200">
            <div class="flex justify-center px-3 py-1 md:px-6 md:py-3">
              <a href="#" class="flex items-center md:mx-8">
                <img
                  src="@/assets/full_logo.png"
                  class="h-12"
                  alt="Zulu Logo"
                />
              </a>
            </div>
          </nav>
        </header>
        <!-- Content -->
        <div>
          <router-view></router-view>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { IonContent, IonPage } from "@ionic/vue";

const store = useStore();
const route = useRoute();

onMounted(() => {
  store.dispatch("firebaseData/bindCurrentUser");
  store.dispatch("profile/getProfile", route.params.username);
});
</script>
