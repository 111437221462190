import { ref, computed } from "vue";
import { db, auth } from "@/plugins/firebase";

export const currentUser = ref();
export async function getCurrentUser() {
  if (auth.currentUser) {
    currentUser.value = await db.collection("users").doc(auth.currentUser.uid);
  }
}

// Offer
export const offer = ref();
export async function getOffer(packageId) {
  try {
    offer.value = await db.collection("packages").doc(packageId).get();
    offer.value;
  } catch (error) {
    return;
  }
}
export const isPackageOwner = computed(() =>
  offer.value ? offer.value?.data().owner.id === auth.currentUser?.uid : false
);

(async () => {
  getCurrentUser();
})();
