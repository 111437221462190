import { auth } from "@/plugins/firebase";
export async function sendRequest(url, request) {
  const token = await auth.currentUser.getIdToken();

  const mergedRequest = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  // Merge Method
  if (request?.method) {
    mergedRequest.method = request.method;
  }

  // Merge Headers
  if (request?.headers) {
    Object.assign(mergedRequest.headers, request.headers);
  }

  // Merge body
  if (request?.body && typeof request.body !== String) {
    mergedRequest.body = JSON.stringify(request.body);
  } else {
    mergedRequest.body = request?.body;
  }

  const response = await fetch(url, mergedRequest);

  return response;
}
