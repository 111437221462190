<template>
  <ion-page>
    <ion-header class="flex justify-center border-b">
      <ion-toolbar class="flex items-center lg:max-w-screen-sm bg-transparent">
        <ion-title>Earn</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-loading :is-open="isLoading"></ion-loading>
      <div class="flex justify-center">
        <div class="max-w-screen-sm w-full flex flex-col items-center gap-4">
          <!-- Checklist -->
          <section class="flex flex-col gap-2 w-full">
            <ion-list inset>
              <ProfileLinkViewer />

              <!-- Step 1 -->
              <ion-item
                detail
                :router-link="{ name: 'select-username' }"
                router-direction="forward"
              >
                <div>Change username</div>
              </ion-item>

              <!-- Edit Page and Offers -->
              <ion-item
                detail
                :router-link="{ name: 'earn-edit-page' }"
                router-direction="forward"
              >
                <div>Edit page and offers</div>
              </ion-item>
            </ion-list>

            <ion-list inset>
              <!-- Manage Subscription -->
              <ion-item :router-link="{ name: 'earn-manage-plan' }" detail>
                <div>Manage Subscription</div>
              </ion-item>
              <!-- Stripe Dashboard -->
              <ion-item
                @click="handleManageStripe"
                detail
                :detail-icon="openOutline"
              >
                <div>Stripe Dashboard</div>
              </ion-item>
            </ion-list>
          </section>

          <!-- Learn More -->
          <section>
            <div class="text-center text-sm">
              <div>Want more details about earning with Zulu?</div>
              <a
                class="font-bold"
                href="//me.zulu.video/how-earn-works"
                target="_blank"
                >Learn more</a
              >
            </div>
          </section>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import ProfileLinkViewer from "@/components/ProfileLinkViewer";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonList,
  IonItem,
  IonLoading,
} from "@ionic/vue";
import { openOutline } from "ionicons/icons";
import { sendRequest } from "@/utilities/api";
import { ref } from "vue";

const isLoading = ref(false);
const handleManageStripe = async () => {
  isLoading.value = true;

  const response = await sendRequest(`/api/integrations/stripe/login`);

  if (response.status === 200) {
    const responseBody = await response.text();
    window.location.href = responseBody;
  }
  isLoading.value = false;
};
</script>

<style scoped>
ion-toolbar {
  --background: transparent;
}
</style>
