import { ref } from "vue";
import moment from "moment";

// Stream
export const stream = ref();
export const mimeType = ref();
export const isLoadingStream = ref(false);

// Recording
let recorder;
let recordedChunks = [];
export const recordedStream = ref();
export const recordingUrl = ref();
export const isRecording = ref(false);
let startTime;
let endTime;
export const duration = ref();

export const startAudioStream = async () => {
  // Get preferred devices
  const audioDeviceId = localStorage.getItem("preferredAudioDeviceId");

  // Setup audio constraints
  const audioConfig = { audio: true };
  audioConfig.video = false;

  // Set preferred devices
  if (audioDeviceId) {
    audioConfig.audio = {
      deviceId: audioDeviceId,
    };
  }

  isLoadingStream.value = true;
  stream.value = await navigator.mediaDevices.getUserMedia(audioConfig);
  isLoadingStream.value = false;

  // Set preferred device to current track devices
  stream.value.getTracks().forEach((track) => {
    const deviceId = track.getCapabilities().deviceId;
    setInputDevice(deviceId, track.kind);
  });

  document.addEventListener("visibilitychange", async function () {
    console.log("visibility Change", document.hidden);
    if (document.hidden && !isRecording.value) {
      cleanupAudioStream();
    }
  });
};

export const setInputDevice = (deviceId, kind) => {
  if (kind.includes("audio")) {
    localStorage.setItem("preferredAudioDeviceId", deviceId);
  }
};

export const startRecording = () => {
  console.log("starting", stream.value);

  // Get supported mime type
  const types = [
    "video/x-matroska;codecs=h264",
    "video/webm;codecs=vp9,codecs=opus",
    "video/webm;codecs=vp9,opus",
    "video/webm;codecs=vp9",
    "video/webm;codecs=vp8,codecs=opus",
    "video/webm;codecs=vp8,opus",
    "video/webm;codecs=vp8",
    "video/mpeg",
    "video/mp4",
    "video/mpeg4",
  ];

  for (const type of types) {
    const isSupported = MediaRecorder.isTypeSupported(type);
    console.log(`${type} ${isSupported}`);
    if (isSupported) {
      mimeType.value = type;
      break;
    }
  }

  // Start recorder
  recorder = new MediaRecorder(stream.value, {
    mimeType: mimeType.value,
  });
  recorder.start(100);
  isRecording.value = true;

  recorder.ondataavailable = async (event) => {
    // record new chunck
    recordedChunks.push(await event.data);
  };

  startTime = new Date();
};

export const stopRecording = () => {
  console.log("stopping", stream.value);

  recorder.onstop = () => {
    isRecording.value = false;
    endTime = new Date();

    // Calculate duration
    const startTimeMoment = moment(startTime);
    const endTimeMoment = moment(endTime);
    duration.value = endTimeMoment.diff(startTimeMoment);

    recordedStream.value = new Blob(recordedChunks, {
      type: mimeType.value,
    });

    recordingUrl.value = window.URL.createObjectURL(recordedStream.value);
  };

  console.log("recorded", recorder);

  // Stop recorder
  recorder.stop();
};

export const cleanupAudioStream = async () => {
  try {
    stream.value.getAudioTracks().forEach((track) => {
      track.stop();
    });
  } catch (error) {
    console.error(error);
  }

  stream.value = null;
};

export const cleanupRecording = () => {
  recorder = null;
  recordedChunks = [];
  recordedStream.value = null;
  recordingUrl.value = null;
  mimeType.value = null;

  startTime = null;
  endTime = null;
  duration.value = null;
};

export const resetRecorder = async () => {
  await cleanupAudioStream();
  cleanupRecording();
};
