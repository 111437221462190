import moment from "moment";

export function getTimeString(date) {
  let momentDate = date;
  if (!moment.isMoment(momentDate)) {
    momentDate = moment(momentDate);
  }
  //   get UTC to avoid timezone conversion (for hours mostly)
  momentDate = moment.utc(momentDate);
  const hours = momentDate.hours();
  const minutes = momentDate.minutes();
  const seconds = momentDate.seconds();

  const durationStringBuilder = [];

  if (hours) {
    durationStringBuilder.push(hours.toString().padStart(1, "0"));
    durationStringBuilder.push(":");
  }

  //   Minutes
  durationStringBuilder.push(minutes.toString().padStart(hours ? 2 : 1, "0"));
  durationStringBuilder.push(":");

  //   Seconds
  durationStringBuilder.push(seconds.toString().padStart(2, "0"));

  return durationStringBuilder.join("");
}

export function getTimeStringFriendly(duration) {
  {
    let durationString;
    const contentDuration = moment(duration);
    const minutes = contentDuration.minutes();
    const seconds = contentDuration.seconds();

    const durationStringBuilder = [];
    // minutes
    if (minutes) {
      durationStringBuilder.push(minutes.toString());

      if (seconds) {
        durationStringBuilder.push(":");
      } else {
        durationStringBuilder.push("m");
      }
    }

    // seconds
    if (seconds) {
      if (minutes) {
        durationStringBuilder.push(seconds.toString().padStart(2, "0"));
      } else {
        durationStringBuilder.push(seconds.toString());
        durationStringBuilder.push("s");
      }
    }

    durationString = durationStringBuilder.join("");

    return durationString;
  }
}

export function getTimeUnitValue(duration, unit) {
  const durationObject = new Date(duration);
  switch (unit) {
    case "hours":
      return durationObject.getUTCHours();
    case "minutes":
      return durationObject.getUTCMinutes();
    case "seconds":
      return durationObject.getUTCSeconds();
    default:
      throw new Error("Invalid time unit");
  }
}

export function getDurationFromTimeUnitValue(value, unit) {
  switch (unit) {
    case "hours":
      return value * 60 * 60 * 1000;
    case "minutes":
      return value * 60 * 1000;
    case "seconds":
      return value * 1000;
    default:
      throw new Error("Invalid time unit");
  }
}

export function getTextTimeEstimate(text) {
  return Math.max(((text.length ?? 0) / 5 / 183) * 60 * 1000, 3000);
}
