<template>
  <ion-page>
    <ion-tabs v-if="currentUser.id" :class="{ 'vertical-tabs': isLargeScreen }">
      <ion-router-outlet :animated="isLargeScreen"></ion-router-outlet>
      <ion-tab-bar
        slot="bottom"
        class="lg:rounded-r-xl lg:shadow-lg lg:shadow-primary"
        :class="{ hidden: route.meta.hideTabBar }"
      >
        <div class="hidden lg:block bg-primary p-3">
          <img class="w-14" src="@/assets/logo_white_trans_bg.svg" />
        </div>
        <ion-tab-button
          tab="groups"
          href="/groups"
          :class="{ selected: route.path.includes('groups') }"
        >
          <ion-icon
            class="lg:text-white"
            aria-hidden="true"
            :icon="chatbubble"
          />
          <label>Messages</label>

          <div class="absolute flex justify-center ml-6 lg:ml-4 lg:mb-4 mb-7">
            <div
              class="w-3 h-3 lg:w-2.5 lg:h-2.5 bg-danger border border-white lg:border-none rounded-full"
              v-if="store.getters['firebaseData/unreadNotificationsCount']"
            ></div>
          </div>
        </ion-tab-button>

        <ion-tab-button
          tab="earn"
          href="/earn"
          :class="{ selected: route.path.includes('earn') }"
        >
          <div class="textIcon font-bold">$</div>
          <label>Earn</label>
        </ion-tab-button>

        <ion-tab-button
          tab="profile"
          href="/profile"
          :class="{ selected: route.path.includes('profile') }"
        >
          <ion-icon class="g:text-white" aria-hidden="true" :icon="person" />
          <label>Profile</label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
    <div
      v-else
      class="flex justify-center items-center w-full h-full bg-gradient-to-br from-primary to-secondary"
    >
      <div class="flex justify-center items-center animate-pulse">
        <ion-spinner
          name="crescent"
          color="light"
          size="small"
          class="absolute w-48 h-48"
        >
        </ion-spinner>
        <img class="h-9" src="@/assets/logo_white_trans_bg.svg" />
      </div>
    </div>
  </ion-page>
</template>

<script setup>
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  IonSpinner,
} from "@ionic/vue";
import { person, chatbubble } from "ionicons/icons";

import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
const breakpoints = useBreakpoints(breakpointsTailwind);
const isLargeScreen = breakpoints.greaterOrEqual("lg"); // lg and larger

const route = useRoute();

const store = useStore();
const currentUser = computed(() => store.state.firebaseData.currentUser);
onMounted(() => {
  store.dispatch("firebaseData/bindNotifications");
});
</script>

<style scoped>
.vertical-tabs ion-tab-button {
  background-color: rgb(122, 56, 237);
  border-radius: 0.5rem;
  display: inline-block;
  margin-top: 1.7rem;
  width: 40px;
  max-height: 40px;
  color: white;
}
/* For dollar sign text icon */
.vertical-tabs .textIcon,
.vertical-tabs ion-icon {
  color: white;
  @apply text-xl;
}

.vertical-tabs label {
  position: absolute;
  bottom: -14px;
  font-size: 10px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
}

.textIcon {
  @apply text-3xl;
}

ion-tab-button::part(native) {
  overflow: visible;
}

.selected ion-tab-button::part(native) {
  @apply text-primary;
}

.vertical-tabs ion-tab-button:hover {
  background-color: #5014b8;
}

.vertical-tabs ion-tab-bar {
  position: absolute;
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 80px;

  @apply bg-gradient-to-b from-primary to-secondary;
}

ion-tab-bar {
  --background: #fcfbff;
  --color: #ad95ea;
}

.vertical-tabs ion-router-outlet {
  margin-left: 76px;
}

.vertical-tabs ion-tab-button.selected {
  background-color: #5014b8;
}

#navLogo {
  display: none;
  background-color: #6419e6;
}
.vertical-tabs #navLogo {
  display: flex;
}
</style>
>
