<template>
  <div>
    <div
      id="inputSelection"
      class="flex items-center"
      :class="{ 'opacity-25 pointer-events-none': disabled }"
    >
      <slot></slot>
    </div>
    <ion-popover
      id="inputDeviceSelection"
      :is-open="showChangeInputSource"
      trigger="inputSelection"
      alignment="center"
      dismiss-on-select
      @didDismissPopover="showChangeInputSource = false"
    >
      <ion-content>
        <ion-list>
          <!-- Camera devices -->
          <template v-if="props.video">
            <ion-item-divider
              v-if="devices?.filter((e) => e.kind === 'videoinput')?.length"
            >
              <ion-label>Camera</ion-label>
            </ion-item-divider>
            <ion-radio-group :value="selectedVideoDeviceId">
              <template
                v-for="device in devices?.filter(
                  (e) => e.kind === 'videoinput'
                )"
                :key="device.deviceId"
              >
                <ion-item
                  :button="true"
                  :detail="false"
                  @click="handleSelectInputDevice(device)"
                >
                  <ion-radio :value="device.deviceId">
                    {{ device.label }}
                  </ion-radio>
                </ion-item>
              </template>
            </ion-radio-group>
          </template>
          <template v-if="props.audio">
            <ion-item-divider
              v-if="devices?.filter((e) => e.kind === 'audioinput')?.length"
            >
              <ion-label>Microphone</ion-label>
            </ion-item-divider>
            <ion-radio-group :value="selectedAudioDeviceId">
              <template
                v-for="device in devices?.filter(
                  (e) => e.kind === 'audioinput'
                )"
                :key="device.deviceId"
              >
                <ion-item
                  :button="true"
                  :detail="false"
                  @click="handleSelectInputDevice(device)"
                >
                  <ion-radio :value="device.deviceId">
                    {{ device.label }}
                  </ion-radio>
                </ion-item>
              </template>
            </ion-radio-group>
          </template>
        </ion-list>
      </ion-content>
    </ion-popover>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, onMounted } from "vue";
import {
  IonPopover,
  IonContent,
  IonLabel,
  IonList,
  IonItemDivider,
  IonItem,
  IonRadio,
  IonRadioGroup,
} from "@ionic/vue";

const props = defineProps({
  video: Boolean,
  audio: Boolean,
  disabled: Boolean,
});

const showChangeInputSource = ref(false);
watch(showChangeInputSource, () => {
  getDeviceList();
});

const devices = ref();
const getDeviceList = async () => {
  devices.value = await navigator.mediaDevices?.enumerateDevices();
};
onMounted(() => {
  getDeviceList();
});

const selectedAudioDeviceId = ref(
  localStorage.getItem("preferredAudioDeviceId")
);
const selectedVideoDeviceId = ref(
  localStorage.getItem("preferredVideoDeviceId")
);

const handleSelectInputDevice = async (device) => {
  if (device.kind === "videoinput") {
    selectedVideoDeviceId.value = device.deviceId;
    localStorage.setItem("preferredVideoDeviceId", device.deviceId);
  } else if (device.kind === "audioinput") {
    selectedAudioDeviceId.value = device.deviceId;
    localStorage.setItem("preferredAudioDeviceId", device.deviceId);
  }
  emit("change");
};

const emit = defineEmits(["change"]);
</script>

<style scoped>
#inputDeviceSelection {
  --width: 300px;
}
</style>
