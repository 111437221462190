import { auth } from "@/plugins/firebase";
import { sendRequest } from "@/utilities/api";

export const getChecklistStatus = async () => {
  const currentUser = auth.currentUser;
  const response = await sendRequest(
    `/api/users/id/${currentUser.uid}/profile/onboarding/status`
  );
  if (response.status === 200) {
    const responseBody = await response.json();
    return responseBody;
  }

  return;
};
