<template>
  <div class="min-w-[300px] w-full max-w-[375px]">
    <div class="text-center font-bold text-2xl">Create Account</div>
    <div class="flex justify-center">
      <register-user-form @success="redirect()"></register-user-form>
    </div>
    <div class="flex flex-col items-center">
      <p class="pt-1">
        Already have an account?
        <router-link
          :to="{ name: 'login', query: $route.query }"
          href="#"
          class="text-white font-semibold transition duration-150 ease-in-out underline"
          >Sign in</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
import { auth } from "@/plugins/firebase";
import RegisterUserForm from "./RegisterUserForm.vue";

export default {
  components: {
    RegisterUserForm,
  },
  methods: {
    redirect() {
      window.location.href = this.$route.query.redirect ?? "/";
    },
  },
  mounted() {
    if (auth.currentUser) {
      this.$router.replace({ name: "groups-list" });
    }
  },
};
</script>
