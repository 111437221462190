<template>
  <ion-page>
    <ion-header class="flex justify-center border-b">
      <ion-toolbar
        mode="ios"
        class="flex items-center lg:max-w-screen-sm bg-transparent"
      >
        <ion-buttons slot="start">
          <ion-back-button default-href="/groups"></ion-back-button>
        </ion-buttons>
        <ion-title>
          <div class="flex justify-center">Notifications</div>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="flex justify-center">
        <div class="max-w-screen-sm w-full flex justify-center">
          <ion-list
            v-if="isNotificationsReady && notifications?.length"
            :inset="lgAndLarger"
            class="bg-white w-full"
          >
            <ion-item
              v-for="notification in notifications"
              class="min-h-100"
              :key="notification.id"
              @click="handleClick(notification)"
            >
              <div class="overflow-hidden rounded-full h-8 w-8" slot="start">
                <div
                  class="w-full h-full flex justify-center items-center"
                  :style="`background-color: ${notification.iconColor}; color: ${notification.contentColor}`"
                >
                  <ion-icon :icon="notification.icon"></ion-icon>
                </div>
              </div>
              <div class="py-3 lg:py-4 min-h-[50px] cursor-pointer">
                <p v-html="notification.body"></p>
              </div>
              <div slot="end" class="flex items-center opacity-50">
                <div class="text-sm">
                  {{
                    moment(notification.data.createdAt.toDate()).calendar({
                      lastDay: "[Yesterday]",
                      sameDay: "LT",
                      lastWeek: "dddd",
                      sameElse: "L",
                    })
                  }}
                </div>
                <ion-icon :icon="chevronForward"></ion-icon>
              </div>
            </ion-item>
          </ion-list>
          <div v-else class="flex flex-col items-center max-w-[275px]">
            <div
              class="flex flex-col justify-center items-center min-h-48 opacity-25 py-12 px-6 gap-2"
            >
              <ion-icon
                :icon="notificationsOff"
                class="text-5xl my-2"
              ></ion-icon>
              <div class="font-bold text-xl">No notifications</div>
              <div class="text-lg leading-tight text-center">
                Get chatting with people!
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonList,
  IonItem,
  IonIcon,
  IonButtons,
  IonBackButton,
  onIonViewWillLeave,
  useIonRouter,
} from "@ionic/vue";
import { computed, defineEmits } from "vue";
import { useStore } from "vuex";
import {
  notifications as iconNotifications,
  chevronForward,
  notificationsOff,
} from "ionicons/icons";
import { db, auth } from "../../plugins/firebase";
import moment from "moment";

import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
const breakpoints = useBreakpoints(breakpointsTailwind);
const lgAndLarger = breakpoints.greaterOrEqual("lg"); // lg and larger

const store = useStore();
const router = useIonRouter();
const emit = defineEmits(["close"]);

const isNotificationsReady = computed(
  () => store.state.firebaseData.notificationsInitialized
);
const notifications = computed(() =>
  store.state.firebaseData.allNotifications.map((notification) => {
    let body = "";
    let link = null;
    let iconColor = "gray";
    let contentColor = "white";
    let icon = iconNotifications;
    switch (notification.type) {
      case "ADDED_TO_GROUP":
        body = `<strong>${notification.sentBy?.displayName}</strong> has invited you to a group`;
        link = { name: "group", params: { groupId: notification.group?.id } };
        break;
      case "NEW_GROUP_MESSAGE":
        body = `<strong>${
          notification.message?.sentBy?.displayName
        }</strong> sent you a message ${
          notification.message?.group?.title
            ? " in <strong>notification.message.group.title</strong>"
            : ""
        }`;
        link = {
          name: "group",
          params: { groupId: notification.message?.group?.id },
          query: { selectedMessageId: notification.message?.id },
        };
        break;
    }

    return { body, link, iconColor, contentColor, icon, data: notification };
  })
);

const handleClick = (notification) => {
  if (notification.link) {
    console.log("link", notification.link);
    router.replace(notification.link);
    emit("close");
  }
};

onIonViewWillLeave(async () => {
  const unreadNotifications = store.getters[
    "firebaseData/unreadNotifications"
  ]?.filter((e) => e.type !== "NEW_GROUP_MESSAGE");
  for (const notification of unreadNotifications) {
    await db
      .collection("users")
      .doc(auth.currentUser.uid)
      .collection("notifications")
      .doc(notification.id)
      .update({ read: true });
  }
});
</script>

<style scoped>
.selected ion-item::part(native) {
  @apply bg-background-150;
}
</style>
