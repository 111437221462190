<template>
  <ion-page>
    <ion-grid class="w-full p-0">
      <ion-row class="h-full lg:bg-background-200">
        <ion-col
          v-if="group?.type !== 'meeting'"
          class="hidden lg:flex w-[25rem] lg:max-w-[25rem] overflow-hidden rounded-r-xl shadow-xl z-50"
        >
          <!--  the side menu  -->
          <GroupsPage :forceShowMenu="true" class=""></GroupsPage>
        </ion-col>
        <ion-col>
          <!-- the main content -->
          <ion-router-outlet
            :animated="isLargeScreen"
            class="lg:-ml-2"
          ></ion-router-outlet>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-page>
</template>

<script setup>
import { useStore } from "vuex";
import { computed } from "vue";
import GroupsPage from "./GroupsPage";
import { IonPage, IonRouterOutlet, IonGrid, IonRow, IonCol } from "@ionic/vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

const breakpoints = useBreakpoints(breakpointsTailwind);
const isLargeScreen = breakpoints.greaterOrEqual("lg"); // lg and larger

const store = useStore();

const group = computed(() => store.state.firebaseData.selectedGroup);
</script>
