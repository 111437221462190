<template>
  <div class="w-full m-6">
    <form
      class="text-white w-full"
      autocomplete="off"
      @submit.prevent="handleSubmit()"
    >
      <!-- Email input -->
      <div class="relative mb-4" data-te-input-wrapper-init>
        <input
          v-model="form.email"
          type="email"
          placeholder="Email address"
          class="bg-black/20 placeholder:text-white/75 peer block min-h-[auto] w-full rounded-lg py-[0.32rem] px-3 leading-[2.15] focus:outline-white focus:outline-1 transition-all duration-200 ease-linear"
        />
      </div>

      <!-- Password input -->
      <div class="relative mb-8" data-te-input-wrapper-init>
        <input
          v-model="form.password"
          type="password"
          placeholder="Password"
          class="bg-black/20 placeholder:text-white/75 peer block min-h-[auto] w-full rounded-lg py-[0.32rem] px-3 leading-[2.15] focus:outline-white focus:outline-1 transition-all duration-200 ease-linear"
        />
      </div>

      <div class="flex flex-col gap-2 justify-center items-center">
        <button
          type="submit"
          class="w-full rounded-lg bg-white px-7 mb-3 pt-3 pb-2.5 text-sm font-medium uppercase leading-normal text-primary shadow-gray transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
          data-te-ripple-init
          data-te-ripple-color="light"
        >
          Sign in
        </button>
      </div>
    </form>
    <div class="flex flex-col w-full">
      <button
        type="submit"
        class="w-full rounded-lg bg-white/10 px-7 mb-3 pt-3 pb-2.5 text-sm font-medium uppercase leading-normal text-white shadow-gray transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
        data-te-ripple-init
        data-te-ripple-color="light"
        @click="handleGoogle()"
      >
        <font-awesome-icon
          class="mr-4 h-4 cursor-pointer"
          icon="fa-brands fa-google"
        />
        Sign in with Google
      </button>
    </div>
  </div>
</template>

<script>
import { auth, db } from "@/plugins/firebase";
import { toastController } from "@ionic/vue";
import firebase from "firebase/app";
import "firebase/auth";
export default {
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      isLoadingSignIn: false,
    };
  },
  methods: {
    handleSubmit() {
      this.isLoadingSignIn = true;
      auth
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(async (data) => {
          this.isLoadingSignIn = false;

          console.log("data", data);
          console.log("redirect", this.$route.query.redirect);
          await this.$store.dispatch("bindCurrentUser");
          this.$emit("success");
        })
        .catch(async (err) => {
          this.isLoadingSignIn = false;
          const toast = await toastController.create({
            header: "Unable to sign in",
            message: err.message,
            color: "danger",
            duration: 3000,
            position: "top",
          });
          await toast.present();
          console.log(err.message);
        });
    },
    async handleGoogle() {
      var provider = new firebase.auth.GoogleAuthProvider();
      let userResponse;
      // if (isPlatform("mobile")) {
      //   userResponse = await auth.signInWithRedirect(provider);
      // } else {
      //   userResponse = await auth.signInWithPopup(provider);
      // }
      userResponse = await auth.signInWithPopup(provider);

      if (userResponse?.additionalUserInfo?.isNewUser) {
        const info = userResponse?.additionalUserInfo;
        const newUser = await db.collection("users").doc(userResponse.user.uid);

        await newUser.set({
          email: userResponse.user?.email,
          firstName: info.profile?.given_name,
          lastName: info.profile?.family_name,
          photoURL: auth.currentUser?.photoURL,
          displayName: info.profile?.name,
        });

        await this.$store.dispatch("bindCurrentUser");
      }
      this.$emit("success");
    },
  },

  mounted() {
    this.form.email = this.$route.query.email;
  },
};
</script>
