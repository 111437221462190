<template>
  <ion-page>
    <ion-header class="flex justify-center border-b">
      <ion-toolbar class="flex items-center lg:max-w-screen-sm bg-transparent">
        <ion-buttons slot="start">
          <ion-back-button default-href="/earn"></ion-back-button>
        </ion-buttons>
        <ion-title>{{ hasUsername ? "Change" : "Select" }} Username</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="flex justify-center">
        <div
          class="max-w-screen-sm w-full flex flex-col p-4 items-center gap-8"
        >
          <!-- Form -->
          <form
            class="flex flex-col gap-2 w-full"
            @submit.prevent="
              () =>
                !isConfirmedAvailable
                  ? handleCheckAvailability()
                  : handleSetUsername()
            "
          >
            <div class="flex flex-col items-center text-center px-4 py-3 gap-4">
              <div class="text-6xl">🪪</div>
              <div class="text-2xl font-bold">
                {{ hasUsername ? "Change your" : "Select a" }} username
              </div>

              <p v-if="!hasUsername">
                Your username will used in multiple places around Zulu, so take
                some time to think of one you like.
              </p>
              <div v-else>
                <p>Your current username:</p>
                <p class="font-bold text-xl text-primary">
                  @{{ currentUser.username }}
                </p>
              </div>

              <!-- <div class="flex items-center text-primary opacity-25">
                <span class="text-4xl">@ </span
                ><span class="text-2xl font-medium">{{ dynamicUsername }}</span>
              </div> -->
            </div>

            <ion-item class="rounded-xl">
              <span class="text-primary text-xl mr-2">@</span>
              <ion-input
                :placeholder="dynamicUsername"
                @input="handleInput"
                :value="username"
                aria-label="Username"
                label-placement="stacked"
                @keyup.enter="handleCheckAvailability"
              ></ion-input>
            </ion-item>

            <div
              v-if="isConfirmedAvailable || isConfirmedUnavailable"
              class="text-center"
              :class="{
                'text-success': isConfirmedAvailable,
                'text-danger': isConfirmedUnavailable,
              }"
            >
              <span class="font-semibold">@{{ username }}</span>
              {{
                isConfirmedAvailable
                  ? "is available! 🎉"
                  : isConfirmedUnavailable
                  ? "is unavailable 😢"
                  : ""
              }}
            </div>

            <ion-button
              v-if="!isConfirmedAvailable"
              :disabled="!username.length"
              class="w-full"
              type="submit"
            >
              <template v-if="!isLoading">Check Availability</template>

              <ion-spinner v-else name="crescent"></ion-spinner>
            </ion-button>

            <ion-button
              v-if="isConfirmedAvailable"
              color="tertiary"
              class="w-full"
              type="submit"
            >
              <template v-if="!isLoading">
                {{ currentUser.username ? "Change" : "Set" }} Username
              </template>
              <ion-spinner v-else name="crescent"></ion-spinner>
            </ion-button>
          </form>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonBackButton,
  IonButtons,
  IonButton,
  IonInput,
  IonItem,
  IonSpinner,
  onIonViewDidEnter,
  useIonRouter,
} from "@ionic/vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { sendRequest } from "@/utilities/api";

const store = useStore();
const router = useIonRouter();

const currentUser = computed(() => store.state.firebaseData.currentUser);

const isConfirmedUnavailable = ref(false);
const isConfirmedAvailable = ref(false);
const username = ref("");

const hasUsername = computed(() => currentUser.value.username);

const handleInput = (event) => {
  const filteredValue = event.target.value.replace(/[^a-z0-9]+/g, "");
  // Update username value
  username.value = filteredValue;
  // Invalidate availability every time input is made
  isConfirmedAvailable.value = false;
  isConfirmedUnavailable.value = false;
};

const isLoading = ref(false);

const handleCheckAvailability = async () => {
  if (username.value?.length === 0) {
    return;
  }

  isLoading.value = true;

  try {
    isConfirmedUnavailable.value = false;
    isConfirmedAvailable.value = false;

    const response = await sendRequest(`/api/users/username/${username.value}`);

    if (response.status === 200) {
      isConfirmedUnavailable.value = true;
    } else if (response.status === 404) {
      isConfirmedAvailable.value = true;
    }
  } catch (e) {
    console.error(e);
  }
  isLoading.value = false;
};

const handleSetUsername = async () => {
  if (username.value?.length === 0) {
    return;
  }

  isLoading.value = true;

  try {
    const response = await sendRequest(
      `/api/users/id/${currentUser.value.id}`,
      {
        method: "PATCH",
        body: { username: username.value },
      }
    );

    if (response.status === 409) {
      // TODO: Handle username unavailable (rare)
    }

    router.canGoBack()
      ? router.back()
      : router.navigate({ name: "profile" }, "back", "pop");
  } catch (e) {
    console.error(e);
  }
  isLoading.value = false;
};

// username examples
const USERNAME_EXAMPLES = [
  "johnsmith",
  "kellylifecoach",
  "rainbowunicorn",
  "pixeldreamer",
  "aquaadventurer",
  "cosmicjourney",
  "urbanexplorer",
  "serenesoul",
  "techsavvy23",
  "naturenurturer",
  "stargazer87",
  "wanderlustwarrior",
];
let dynamicUsernameExampleIndex = 0;
let dynamicUsernameCurrentWordIndex = 0;
const dynamicUsername = ref("");

const typeWriter = () => {
  // Typing mode
  if (
    dynamicUsernameCurrentWordIndex <
    USERNAME_EXAMPLES[dynamicUsernameExampleIndex].length
  ) {
    dynamicUsername.value = USERNAME_EXAMPLES[
      dynamicUsernameExampleIndex
    ].slice(0, dynamicUsernameCurrentWordIndex + 1);
    dynamicUsernameCurrentWordIndex++;
    setTimeout(typeWriter, 200);
  } else if (
    dynamicUsernameCurrentWordIndex ===
    USERNAME_EXAMPLES[dynamicUsernameExampleIndex].length
  ) {
    dynamicUsernameCurrentWordIndex = 0;
    setTimeout(typeWriter, 1000);
    if (dynamicUsernameExampleIndex + 1 !== USERNAME_EXAMPLES.length) {
      dynamicUsernameExampleIndex++;
    } else {
      dynamicUsernameExampleIndex = 0;
    }
  }
};
onIonViewDidEnter(() => {
  typeWriter();
});
</script>

<style scoped>
ion-toolbar {
  --background: transparent;
}
</style>
