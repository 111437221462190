<template>
  <ion-page>
    <ion-content>
      <ion-router-outlet :animated="isLargeScreen"></ion-router-outlet>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonRouterOutlet, IonPage, IonContent } from "@ionic/vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
const breakpoints = useBreakpoints(breakpointsTailwind);
const isLargeScreen = breakpoints.greaterOrEqual("lg");
</script>
