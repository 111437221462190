<template>
  <div class="flex flex-col gap-8 justify-start items-center mt-24">
    <div class="text-3xl font-extrabold text-center text-black">
      Thank you for your purchase!
    </div>
    <div>
      <a
        class="text-2xl bg-gradient-to-br from-primary to-secondary shadow-lg text-white font-bold rounded-xl px-6 py-3"
        :href="`/groups/${route.query.purchaseId}`"
      >
        Go to conversation →
      </a>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";
// import { useStore } from 'vuex';
// const store = useStore()

const route = useRoute();

onMounted(() => {
  // store.dispatch('')
});
</script>
