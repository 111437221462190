import { Ionic, IonicConfig } from "./ionic";
import { FontAwesomeIconSetup } from "./font-awesome";
import VueGtag from "vue-gtag";
import router from "@/router";
import "image-capture";

export const installPlugins = (app) => {
  // Font Awesome
  app.component("FontAwesomeIcon", FontAwesomeIconSetup());

  // Ionic
  app.use(Ionic, IonicConfig);

  app.use(
    VueGtag,
    {
      config: {
        id: "G-7Z41L36H26",
      },
    },
    router
  );
};
