<template>
  <ion-page>
    <!-- Menu Section -->
    <ion-header :class="{ 'lg:hidden': !props.forceShowMenu }">
      <ion-toolbar mode="ios" class="flex items-center">
        <div v-if="!isLargeScreen" class="lg:hidden justify-center flex">
          <img
            class="h-10 bg-primary p-2 m-1 rounded-lg"
            src="@/assets/logo_white_trans_bg.svg"
          />
        </div>
        <ion-title v-if="isLargeScreen">
          <div class="flex justify-center">Messages</div>
        </ion-title>

        <!-- <ion-buttons slot="end">
          <ion-button @click="router.push({ name: 'notifications' })">
            <ion-icon
              class="text-primary-tint"
              :icon="notifications"
            ></ion-icon>
            <div class="absolute flex justify-center ml-3 mb-4">
              <div
                class="w-2.5 h-2.5 bg-danger border border-white rounded-full"
                v-if="store.getters['firebaseData/unreadNotificationsCount']"
              ></div>
            </div>
          </ion-button>
        </ion-buttons> -->
      </ion-toolbar>
    </ion-header>
    <ion-content
      class="no-scrollbar"
      :class="{ 'lg:hidden': !props.forceShowMenu }"
    >
      <div
        class="min-h-full no-scrollbar flex flex-col"
        :class="{
          'bg-background-100': isPlatform('desktop') || isPlatform('tablet'),
        }"
      >
        <!-- Checklist -->
        <SetupChecklistCompact
          v-if="currentUserMetadata?.userType !== 'customer'"
        ></SetupChecklistCompact>
        <!-- Groups List -->
        <GroupsList></GroupsList>
      </div>
    </ion-content>

    <!-- Empty Data Fallback -->
    <ion-content
      v-if="!isPlatform('mobile')"
      class="hidden"
      :class="{ 'lg:flex': !props.forceShowMenu }"
    >
      <div class="bg-white w-full h-full">
        <div
          class="w-full h-full bg-violet-100 opacity-75 flex flex-col justify-end items-center text-2xl font-bold p-4 gap-4"
        ></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import SetupChecklistCompact from "./SetupChecklistCompact";

import { auth } from "@/plugins/firebase";
import "firebase/firestore";
import { computed, defineProps, onMounted } from "vue";
import {
  isPlatform,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
  // IonButton,
  // IonButtons,
  // IonIcon,
  onIonViewWillEnter,
} from "@ionic/vue";
// import { notifications } from "ionicons/icons";
import GroupsList from "./GroupsList";
import { useStore } from "vuex";
// import { useIonRouter } from "@ionic/vue";
import { StatusBar, Style } from "@capacitor/status-bar";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
const breakpoints = useBreakpoints(breakpointsTailwind);
const isLargeScreen = breakpoints.greaterOrEqual("lg");

const props = defineProps({
  forceShowMenu: Boolean,
});

const store = useStore();
// const router = useIonRouter();

const currentUserMetadata = computed(
  () => store.state.firebaseData.currentUserMetadata
);

const getGroups = async () => {
  if (auth.currentUser?.uid && !store.state.firebaseData.groups?.length) {
    store.dispatch("firebaseData/bindGroups");
  }
};

onIonViewWillEnter(() => {
  if (isPlatform("capacitor")) {
    StatusBar.setStyle({ style: Style.Light });
  }

  document
    .querySelector("[name='theme-color']")
    ?.setAttribute("content", "#fefefe");
  getGroups();
});

onMounted(() => {
  if (isPlatform("capacitor")) {
    StatusBar.setStyle({ style: Style.Light });
  }

  getGroups();
});
</script>

<style scoped>
ion-toolbar {
  --background: white;
}
</style>
