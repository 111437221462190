<template>
  <ion-page>
    <ion-header class="flex justify-center border-b">
      <ion-toolbar class="flex items-center lg:max-w-screen-sm bg-transparent">
        <ion-buttons slot="start">
          <ion-back-button default-href="/earn/page/edit"></ion-back-button>
        </ion-buttons>
        <ion-title>Edit Offer</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="flex justify-center">
        <div class="max-w-screen-sm w-full flex flex-col items-center lg:p-4">
          <!-- Create Header -->
          <section v-if="isGuided">
            <div class="flex flex-col items-center text-center px-4 py-3 gap-4">
              <div class="text-2xl font-bold">Configure your offer</div>

              <p class="text-sm text-gray-500">
                This is what your client is purchasing from you.
                <br />
                Make sure it is clear to the client what you are offer, how much
                it costs, and how long the interaction will be.
              </p>
            </div>
          </section>

          <!-- Status -->
          <section class="w-full p-4" v-if="offer?.title">
            <!-- Status -->
            <div
              class="flex items-center rounded-xl bg-violet-100 px-4 py-3 cursor-pointer w-full"
            >
              <div class="flex items-center mr-3">
                <ion-icon
                  :icon="currentStatusInfo.icon"
                  class="text-2xl"
                  :class="currentStatusInfo.textClass"
                ></ion-icon>
              </div>

              <div class="w-full flex items-center gap-2">
                <span class="text-sm">Status: </span>
                <span class="font-bold">{{ currentStatusInfo.title }}</span>
              </div>

              <ion-button
                @click="setShowStatusChange(true)"
                id="statusChange"
                fill="clear"
                size="small"
                >Change</ion-button
              >
            </div>

            <!-- Status change modal -->
            <ion-modal
              id="statusChangeModal"
              ref="statusChangeModal"
              :is-open="isOpenStatusChange"
              :initial-breakpoint="!isMdAndLarger ? 0.5 : undefined"
              :breakpoints="!isMdAndLarger ? [0, 0.5, 1] : undefined"
              @ionModalDidDismiss="setShowStatusChange(false)"
            >
              <ion-content>
                <div class="py-4 px-3 flex flex-col gap-2">
                  <template
                    v-for="status in statuses.filter((e) => !e.hideOption)"
                    :key="status.title"
                  >
                    <!-- Published -->
                    <div
                      class="flex rounded-xl bg-gray-100 px-4 py-3 gap-3 cursor-pointer w-full border-2"
                      :class="{
                        'border-primary': offer?.status === status.value,
                      }"
                      @click="setStatus(status.value)"
                    >
                      <div class="flex items-center">
                        <ion-checkbox
                          disabled
                          :checked="offer?.status === status.value"
                          aria-label="Published"
                        ></ion-checkbox>
                      </div>

                      <div class="flex flex-col w-full mt">
                        <div class="font-bold">{{ status.title }}</div>

                        <p class="text-sm">{{ status.description }}</p>
                      </div>
                    </div>
                  </template>
                </div>
              </ion-content>
            </ion-modal>
          </section>

          <section>
            <ion-button
              size="small"
              :href="`/checkout/register?packageId=${route.params.offerId}`"
              target="_blank"
            >
              Test Purchase
            </ion-button>
          </section>

          <!-- Offer Form -->
          <section
            class="w-full lg:min-h-[20rem] flex flex-col"
            id="editOfferForm"
            v-if="offer?.title"
          >
            <div>
              <!-- General -->
              <ion-list-header>
                <ion-label class="my-0 text-lg">General</ion-label>
              </ion-list-header>
              <ion-list inset>
                <ion-item>
                  <ion-input
                    label="Title"
                    label-placement="stacked"
                    placeholder="Eye-catching title, maybe even some emojis!"
                    type="text"
                    v-model="offer.title"
                  />
                </ion-item>
                <ion-item>
                  <ion-textarea
                    id="descriptionInput"
                    label="Description"
                    label-placement="stacked"
                    placeholder="Explain what your client will get from this offer.."
                    auto-grow
                    class="min-h-72"
                    v-model="offer.description"
                  ></ion-textarea>
                </ion-item>
              </ion-list>
            </div>

            <!-- Offer payment type -->
            <div>
              <ion-list-header>
                <ion-label class="my-0 text-lg">
                  <!-- Billing Frequency and Duration -->
                  Price and Duration
                </ion-label>
              </ion-list-header>
              <!-- <div class="px-4 mt-4">
                <ion-segment v-model="billingFrequency">
                  <ion-segment-button value="one-time">
                    <ion-label>One-time</ion-label>
                  </ion-segment-button>
                  <ion-segment-button disabled="true" value="recurring">
                    <ion-label>Recurring</ion-label>
                  </ion-segment-button>
                </ion-segment>
              </div> -->
              <ion-list inset class="py-0">
                <ion-item>
                  <ion-input
                    label="Price (USD)"
                    label-placement="stacked"
                    placeholder="0"
                    type="number"
                    v-model="offer.price"
                  />
                </ion-item>
                <ion-item>
                  <div class="flex w-1/2">
                    <ion-input
                      label="Hours"
                      type="number"
                      label-placement="stacked"
                      placeholder="0"
                      max="24"
                      v-model="durationHours"
                      class="w-full"
                    />
                  </div>
                  <div class="flex w-1/2">
                    <ion-input
                      label="Minutes"
                      type="number"
                      label-placement="stacked"
                      placeholder="0"
                      max="60"
                      v-model="durationMinutes"
                      class="w-full"
                    />
                  </div>
                </ion-item>
              </ion-list>
            </div>

            <div>
              <ion-list-header class="flex items-center -mb-2">
                <ion-label class="my-0 text-lg">Onboarding Messages</ion-label>
                <EditOnboardingMessage
                  v-if="hasOnboardingMessages"
                  :offerId="offerRef?.id"
                  @messageModified="getOffer()"
                >
                  <ion-button class="text-sm my-0">Add Message</ion-button>
                </EditOnboardingMessage>
              </ion-list-header>
              <ion-list v-if="hasOnboardingMessages" inset>
                <EditOnboardingMessage
                  v-for="message in onboardingMessages"
                  :key="message.id"
                  :offerId="offerRef?.id"
                  :messageId="selectedOnboardingMessageId"
                  @messageModified="getOffer()"
                >
                  <ion-item
                    detail
                    @click="selectedOnboardingMessageId = message.id"
                  >
                    <div class="flex">
                      {{ message.title }}
                    </div>
                  </ion-item>
                </EditOnboardingMessage>
              </ion-list>
              <div
                v-else
                class="flex flex-col items-center rounded-lg bg-gray-50 border border-dashed border-gray-300 px-3 pt-2 mx-4 my-2"
              >
                <div
                  class="text-center text-sm font-medium max-w-sm text-gray-400"
                >
                  Onboarding messages are prerecorded/prewritten messages that
                  are delivered automatically to your client as soon as they
                  purchase your offer
                </div>
                <EditOnboardingMessage
                  :offerId="offerRef?.id"
                  @messageModified="getOffer()"
                >
                  <ion-button class="text-sm my-0" fill="clear">
                    Create onboarding message
                  </ion-button>
                </EditOnboardingMessage>
              </div>
            </div>
          </section>

          <!-- Offer Actions -->
          <section
            class="w-full px-3 mb-4 py-4 sticky bottom-0 bg-white/25 backdrop-blur-lg"
          >
            <div>
              <div class="flex flex-col">
                <ion-button expand="block" @click="handleSaveOffer"
                  >Save offer</ion-button
                >
                <ion-alert
                  :is-open="isOpenConfirmStatus"
                  header="Publish Offer?"
                  sub-header="This offer is still marked as a draft and is not visible to customers"
                  :buttons="[
                    {
                      text: 'Keep as Draft',
                      handler: () => {
                        saveOffer();
                      },
                    },
                    {
                      text: 'Publish Offer',
                      handler: () => {
                        setStatus('published');
                        saveOffer();
                      },
                    },
                  ]"
                  @didDismiss="isOpenConfirmStatus = false"
                ></ion-alert>
              </div>
            </div>
          </section>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import EditOnboardingMessage from "./EditOnboardingMessage";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonBackButton,
  IonButtons,
  IonInput,
  IonList,
  IonItem,
  IonAlert,
  IonTextarea,
  IonLabel,
  IonListHeader,
  IonButton,
  useIonRouter,
  // IonSegment,
  // IonSegmentButton,
  IonIcon,
  IonModal,
  IonCheckbox,
  onIonViewWillEnter,
} from "@ionic/vue";
import { eye, eyeOff, warning, document } from "ionicons/icons";
import { computed, reactive, ref } from "vue";
// import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

import { db } from "@/plugins/firebase";
import {
  getTimeUnitValue,
  getDurationFromTimeUnitValue,
} from "@/utilities/time";

const route = useRoute();
const router = useIonRouter();

// const store = useStore();
// const currentUser = computed(() => store.state.firebaseData.currentUser);

const breakpoints = useBreakpoints(breakpointsTailwind);
const isMdAndLarger = breakpoints.greaterOrEqual("md"); // lg and larger

const isGuided = computed(() => {
  return route.query?.guided;
});

// OFFER
const offerRef = ref();
const offer = reactive({});
const durationHours = ref();
const durationMinutes = ref();
const onboardingMessages = ref([]);
const selectedOnboardingMessageId = ref();
const getOffer = async () => {
  // Get offer
  offerRef.value = db.collection("packages").doc(route.params.offerId);
  const offerData = (await offerRef.value.get()).data();
  const onboardingMessagesDocs = await offerRef.value
    .collection("onboarding")
    .get();
  const onboardingMessagesData =
    onboardingMessagesDocs?.docs
      ?.map((e) => ({
        id: e.id,
        ...e.data(),
      }))
      ?.sort((a, b) => a.createdAt?.toDate() - b.createdAt?.toDate()) ?? [];

  // Set up duration
  const durationHoursCalculated = getTimeUnitValue(
    offerData.duration ?? 0,
    "hours"
  );
  const durationMinutesCalculated = getTimeUnitValue(
    offerData.duration ?? 0,
    "minutes"
  );

  // Set state variables and default to nulls if 0 (so placeholder is consistent)
  durationHours.value = durationHoursCalculated
    ? durationHoursCalculated
    : null;
  durationMinutes.value = durationMinutesCalculated
    ? durationMinutesCalculated
    : null;

  // Set offer and onboarding messages
  Object.assign(offer, offerData);
  onboardingMessages.value = onboardingMessagesData;
};
onIonViewWillEnter(() => {
  getOffer();
});
const hasOnboardingMessages = computed(() => {
  return onboardingMessages.value?.length;
});

// STATUS
const isOpenStatusChange = ref(false);
const setShowStatusChange = (value) => {
  isOpenStatusChange.value = value;
};
const currentStatusInfo = computed(() =>
  statuses.find((e) => e.value === offer?.status)
);
const statuses = [
  {
    title: "Published",
    value: "published",
    icon: eye,
    textClass: "text-primary",
    description: "Publicly visible. Clients can purchase this offer",
  },
  {
    title: "Unlisted",
    value: "unlisted",
    icon: eyeOff,
    textClass: "text-primary",
    hideOption: true,
    description:
      "Not publicly visible. Cannot be purchased, but existing clients can continue to renew or extend",
  },
  {
    title: "Inactive",
    value: "inactive",
    icon: warning,
    textClass: "text-danger",
    description:
      "Not visible to anyone except you. Clients can NOT purchase this offer",
  },
  {
    title: "Draft",
    value: "draft",
    icon: document,
    hideOption: true,
    textClass: "text-gray-400",
  },
];
const setStatus = (newStatus) => {
  offer.status = newStatus;
};

// Billing Info
// const billingFrequency = ref("one-time");

const isOpenConfirmStatus = ref(false);
const handleSaveOffer = () => {
  if (offer.status === "draft") {
    isOpenConfirmStatus.value = true;
  } else {
    saveOffer();
  }
};
const saveOffer = async () => {
  // Calculate duration value
  const duration =
    getDurationFromTimeUnitValue(durationHours.value ?? 0, "hours") +
    getDurationFromTimeUnitValue(durationMinutes.value ?? 0, "minutes");

  // Set up data for updating
  const updateOfferData = {
    title: offer.title ?? null,
    description: offer.description ?? null,
    duration: duration ?? null,
    price: offer.price ?? null,
    status: offer.status ?? null,
    type: "time", // TODO: add support for monthly billing
  };
  // Update package
  await offerRef.value.update(updateOfferData);

  // Go back to profile edit page
  if (router.canGoBack()) {
    router.back();
  } else {
    router.navigate(
      {
        name: "earn-edit-page",
      },
      "back",
      "pop"
    );
  }
};
</script>

<style>
ion-toolbar {
  --background: transparent;
}
#editOfferForm .native-textarea {
  min-height: 10rem;
}

#editOfferForm ion-input,
#editOfferForm ion-textarea {
  --placeholder-opacity: 0.2;
}

ion-modal#statusChangeModal {
  --max-width: theme(screens.sm);
}
</style>
