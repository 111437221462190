import { createStore } from "vuex";
import firebaseData from "./firebaseData";
import checkout from "./checkout";
import profile from "./profile";

import { vuexfireMutations } from "@xquick-code/vuexfire";

// TODO: fix stupid webrecorder not allowing streams to be swapped!!! Something stupid is going on with how it packages the video on the second time running the recorder...
// import WebRecorder from "@/plugins/replaceable-media-recorder/WebRecorder";

const store = createStore({
  state: {
    route: null,
  },
  mutations: {
    ...vuexfireMutations,
    SET_ROUTE(state, route) {
      state.route = route;
    },
  },
  modules: {
    firebaseData,
    profile,
    checkout,
  },
});

export default store;
