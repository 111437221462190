function waitForNextEvent(element, type) {
  return new Promise((resolve) => {
    element.addEventListener(type, resolve, { once: true });
  });
}

export async function promptForFileSelection(config) {
  const fileInput = Object.assign(document.createElement("input"), {
    type: "file",
    accept: config?.accept ?? "*",

    multiple: config.multiple,
    style: "display: none;",
  });

  document.body.appendChild(fileInput);

  const promise = waitForNextEvent(fileInput, "change");
  fileInput.value = null;
  fileInput.click();

  await promise;

  document.body.removeChild(fileInput);
  return Object.values(fileInput.files);
}

export function getFileBlobURLs(files) {
  return files.map((file) => URL.createObjectURL(file));
}
