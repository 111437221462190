<template>
  <ion-page>
    <ion-header class="flex justify-center border-b">
      <ion-toolbar class="flex items-center lg:max-w-screen-sm bg-transparent">
        <ion-buttons slot="start">
          <ion-back-button default-href="/earn/page/edit"></ion-back-button>
        </ion-buttons>
        <ion-title>Create Offer</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="flex justify-center">
        <div class="max-w-screen-sm w-full flex flex-col items-center lg:p-4">
          <!-- Create Header -->
          <section>
            <div class="flex flex-col items-center text-center px-4 py-3 gap-4">
              <div class="text-6xl">💰</div>
              <div class="text-2xl font-bold">Create a new offer</div>

              <p class="text-sm text-gray-500">
                This is what your client is purchasing from you.
              </p>

              <p class="text-center font-medium">
                Let's start by giving your offer a nice eye-catching title!
              </p>
            </div>
          </section>

          <!-- Offer Form -->
          <section class="w-full flex flex-col" id="editOfferForm">
            <div>
              <!-- General -->
              <ion-list inset>
                <ion-item>
                  <ion-input
                    aria-label="Title"
                    placeholder="Title"
                    type="text"
                    v-model="offer.title"
                  />
                </ion-item>
              </ion-list>
            </div>
          </section>

          <!-- Offer Actions -->
          <section class="w-full px-3">
            <div>
              <div class="flex flex-col">
                <ion-button
                  :disabled="!offer?.title?.length"
                  expand="block"
                  @click="handleNext"
                  >Next</ion-button
                >
              </div>
            </div>
          </section>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonBackButton,
  IonButtons,
  IonInput,
  IonList,
  IonItem,
  IonButton,
  useIonRouter,
} from "@ionic/vue";
import { reactive, computed } from "vue";
import { db } from "@/plugins/firebase";
import { useStore } from "vuex";
// import { useRoute } from "vue-router";

// const route = useRoute();
const router = useIonRouter();
const store = useStore();
const currentUser = computed(() => store.state.firebaseData.currentUser);

const offer = reactive({
  status: "draft",
  owner: db.collection("users").doc(currentUser.value.id),
});

const handleNext = async () => {
  // Create new package
  const newPackageRef = db.collection("packages").doc();
  await newPackageRef.set(offer);

  // Link to profile
  const profileRef = db
    .collection("users")
    .doc(currentUser.value.id)
    .collection("pages")
    .doc("profile");
  const currentProfile = (await profileRef.get()).data();
  const currentPackages = currentProfile.packages ?? [];
  const mergedPackages = [...currentPackages, newPackageRef];
  await profileRef.update({ packages: mergedPackages });

  console.log();

  router.navigate(
    {
      name: "earn-edit-page-edit-offer",
      params: { offerId: newPackageRef.id },
      query: { guided: true },
    },
    "none",
    "pop"
  );
};
</script>

<style>
ion-toolbar {
  --background: transparent;
}
#editOfferForm .native-textarea {
  min-height: 10rem;
}

#editOfferForm ion-input,
#editOfferForm ion-textarea {
  --placeholder-opacity: 0.2;
}

ion-modal#statusChangeModal {
  --max-width: theme(screens.sm);
}
</style>
