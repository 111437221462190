<template>
  <div class="w-full flex justify-center">
    <div class="max-w-screen-xl flex flex-col items-center">
      <div
        v-if="isPackageOwner"
        class="flex flex-col gap-2 items-center bg-red-100 border border-red-300 sm:rounded-lg text-red-700 max-w-screen-sm p-2"
      >
        <p class="text-center">
          <span class="font-bold">PREVIEW MODE</span>
          <br />
          This is where your customer will be redirected to Stripe where payment
          information will be collected and billed.
          <br />
          We are unable to simulate this part of the process.
        </p>
        <div class="flex items-center">
          <button
            class="bg-red-500 rounded-md px-4 py-3 text-white font-bold"
            @click="handleNextStep()"
          >
            Next Step →
          </button>
        </div>
      </div>
      <div
        v-if="!isPackageOwner || isLoading"
        class="flex flex-col w-full items-center justify-center text-black"
      >
        <ion-spinner
          name="crescent"
          class="w-16 h-16"
          color="primary"
        ></ion-spinner>
        <div class="text-3xl lg:text-4xl font-bold lg:mt-16 p-6 text-center">
          Redirecting to Stripe for payment
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { sendRequest } from "@/utilities/api";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { IonSpinner } from "@ionic/vue";
import { auth } from "@/plugins/firebase";
import { isPackageOwner, getOffer } from "./checkout";

const route = useRoute();
const isLoading = ref(false);

const handleNextStep = async () => {
  isLoading.value = true;
  try {
    const response = await sendRequest(
      `/api/checkout/purchase/${route.query.packageId}/test`,
      { method: "POST" }
    );

    const responseBody = await response.json();
    if (responseBody.redirectURL) {
      window.location.href = responseBody.redirectURL;
    }
  } catch (e) {
    console.error("Error", e);
  }
  isLoading.value = false;
};

onMounted(async () => {
  if (auth.currentUser) {
    await getOffer(route.query.packageId);
  }

  if (!isPackageOwner.value) {
    isLoading.value = true;

    try {
      const response = await sendRequest(
        `/api/checkout/purchase/${route.query.packageId}`,
        { method: "POST" }
      );

      const responseBody = await response.json();
      if (responseBody.checkoutUrl) {
        setTimeout(() => {
          window.location.href = responseBody.checkoutUrl;
        }, 1500);
      }
    } catch (e) {
      console.error("Error", e);
    }
    isLoading.value = false;
  }
});
</script>
