<template>
  <div class="min-w-[300px] w-full max-w-[375px]">
    <div class="text-center font-bold text-2xl">Sign In</div>
    <div class="flex justify-center">
      <login-user-form @success="redirect()"></login-user-form>
    </div>
    <div class="flex flex-col items-center">
      <p class="pt-1">
        Don't have an account?
        <router-link
          :to="{ name: 'register', query: $route.query }"
          href="#"
          class="text-white font-semibold transition duration-150 ease-in-out underline"
          >Sign up</router-link
        >
      </p>
      <p class="pt-4">
        <RouterLink
          :to="{ name: 'reset-password' }"
          class="text-white underline"
          >Forgot password?</RouterLink
        >
      </p>
    </div>
  </div>
</template>

<script>
import { auth, db } from "@/plugins/firebase";
import { isPlatform, toastController } from "@ionic/vue";
import firebase from "firebase/app";
import "firebase/auth";
import LoginUserForm from "./LoginUserForm.vue";
export default {
  components: {
    LoginUserForm,
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      isLoadingSignIn: false,
    };
  },
  methods: {
    handleSubmit() {
      this.isLoadingSignIn = true;
      auth
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(async (data) => {
          this.isLoadingSignIn = false;

          console.log("data", data);
          console.log("redirect", this.$route.query.redirect);
          await this.$store.dispatch("bindCurrentUser");
          this.redirect();
        })
        .catch(async (err) => {
          this.isLoadingSignIn = false;
          const toast = await toastController.create({
            header: "Unable to sign in",
            message: err.message,
            color: "danger",
            duration: 3000,
            position: "top",
          });
          await toast.present();
          console.log(err.message);
        });
    },

    async handleGoogle() {
      var provider = new firebase.auth.GoogleAuthProvider();
      let userResponse;
      if (isPlatform("mobile")) {
        userResponse = await auth.signInWithRedirect(provider);
      } else {
        userResponse = await auth.signInWithPopup(provider);
      }
      if (userResponse?.additionalUserInfo?.isNewUser) {
        const info = userResponse?.additionalUserInfo;
        const newUser = await db.collection("users").doc(userResponse.user.uid);

        await newUser.set({
          email: userResponse.user?.email,
          firstName: info.profile?.given_name,
          lastName: info.profile?.family_name,
          photoURL: auth.currentUser?.photoURL,
          displayName: info.profile?.name,
        });

        await this.$store.dispatch("bindCurrentUser");
      }
      this.redirect();
    },

    redirect() {
      window.location.href = this.$route.query.redirect ?? "/";
    },

    isPlatform,

    async impersonateUser() {
      const token = this.$route.query.impersonate;

      await auth.signInWithCustomToken(token);
      localStorage.setItem("impersonating", true);
    },
  },
  async mounted() {
    console.log("route", this.$route);
    if (this.$route.query?.impersonate) {
      await this.impersonateUser();
    }
    if (auth.currentUser) {
      this.$router.replace({ name: "groups-list" });
    }
  },
};
</script>
