<template>
  <ion-page ref="page" id="groupPage">
    <ion-header translucent class="lg:pl-2">
      <ion-toolbar mode="ios" class="flex items-center">
        <ion-buttons slot="start">
          <ion-back-button text="" default-href="/groups"></ion-back-button>
        </ion-buttons>
        <ion-title>
          <div class="w-full flex justify-center">
            <AvatarGroup
              v-if="group?.id"
              class="flex-none transition"
              :users="group.members"
              :display-limit="2"
              type="square"
              size="medium"
            />
          </div>
        </ion-title>
        <!-- <ion-buttons slot="end">
          <GroupSettings :page="page" />
        </ion-buttons> -->
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-y="false" :scroll-x="false" fullscreen>
      <TimelineViewer></TimelineViewer>
    </ion-content>
    <ion-footer class="bg-white safe-b">
      <ComposeFooter />
    </ion-footer>
  </ion-page>
</template>

<script setup>
import TimelineViewer from "./timeline/TimelineViewer.vue";
import ComposeFooter from "./ComposeFooter";
// import GroupSettings from "./GroupSettings";

import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import {
  IonButtons,
  IonBackButton,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonFooter,
  onIonViewDidEnter,
} from "@ionic/vue";
import AvatarGroup from "@/components/AvatarGroup";

import { useStore } from "vuex";

const route = useRoute();
const store = useStore();

const page = ref(null);

// Group
const group = computed(() => store.state.firebaseData.selectedGroup);

const bindGroup = async () => {
  const groupId = route.params?.groupId;

  await store.dispatch("firebaseData/bindSelectedGroup", {
    groupId,
  });
};

onIonViewDidEnter(async () => {
  bindGroup();
});

onMounted(() => {
  bindGroup();
});
</script>

<style scoped>
@media screen {
}
#groupPage ion-toolbar {
  --background: transparent;
  --ion-color-base: transparent !important;
  --padding-bottom: 0.8rem;
  --padding-top: 0.8rem;
}

#groupPage ion-content::part(scroll) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
