<template>
  <div class="min-w-[300px] w-full max-w-[375px]">
    <div class="flex justify-center">
      <div class="flex flex-col items-center w-full gap-6 mx-6">
        <div class="text-2xl font-medium text-center">Reset Password</div>
        <form
          class="text-white w-full"
          autocomplete="off"
          @submit.prevent="handleSubmit()"
        >
          <!-- Email input -->
          <div class="relative mb-6" data-te-input-wrapper-init>
            <input
              v-model="form.email"
              type="email"
              placeholder="Email address"
              class="bg-black/20 placeholder:text-white/75 peer block min-h-[auto] w-full rounded-lg py-[0.32rem] px-3 leading-[2.15] focus:outline-white focus:outline-1 transition-all duration-200 ease-linear"
            />
          </div>

          <div class="flex flex-col gap-2 justify-center items-center">
            <button
              type="submit"
              class="w-full rounded-lg bg-white px-7 mb-3 pt-3 pb-2.5 text-sm font-medium leading-normal text-primary shadow-gray transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              Request Password Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from "@/plugins/firebase";
import { isPlatform, toastController } from "@ionic/vue";
export default {
  data() {
    return {
      form: {
        email: null,
      },
      isLoadingReset: false,
    };
  },
  methods: {
    handleSubmit() {
      this.isLoadingReset = true;
      auth
        .sendPasswordResetEmail(this.form.email)
        .then(async () => {
          this.isLoadingReset = false;

          const toast = await toastController.create({
            header: "Password Reset Email Sent!",
            message: "Check your email for a password reset email",
            color: "success",
            duration: 5000,
            position: "top",
          });
          await toast.present();
        })
        .catch(async (err) => {
          this.isLoadingReset = false;
          const toast = await toastController.create({
            header: "Unable to send password reset",
            message: err.message,
            color: "danger",
            duration: 5000,
            position: "top",
          });
          await toast.present();
          console.log(err.message);
        });
    },

    isPlatform,
  },
};
</script>
