<template>
  <ion-item detail="false" @click="handleCopyClicked">
    <ion-input
      :value="profileLink"
      disabled
      label="Profile Link"
      label-placement="floating"
      class="w-full pointer-events-none"
    ></ion-input>
    <div slot="end" class="flex">
      <Transition mode="out-in" name="fade">
        <div
          v-if="showCopiedMessage"
          class="flex justify-center items-center py-1 px-2 ml-2 bg-primary text-white font-semibold rounded text-xs"
        >
          Copied!
        </div>
        <button
          v-else
          class="flex justify-center items-center py-1 px-2 ml-2 hover:bg-violet-200 text-primary font-semibold rounded text-xs"
        >
          Copy
        </button>
      </Transition>
      <a
        class="flex justify-center items-center p-1 ml-2 bg-violet-100 transition-all hover:bg-violet-200 rounded cursor-pointer"
        :href="profileLink"
        target="_blank"
      >
        <ion-icon class="text-black" :icon="openOutline"></ion-icon>
      </a>
    </div>
  </ion-item>
</template>

<script setup>
import { IonInput, IonItem, IonIcon } from "@ionic/vue";
import { computed, ref } from "vue";
import { openOutline } from "ionicons/icons";
import { useStore } from "vuex";

const isLocalhost = location.hostname === "localhost";

const store = useStore();

const currentUser = computed(() => store.state.firebaseData.currentUser);
const profileLink = computed(
  () =>
    `${isLocalhost ? "http://localhost:5007" : "https://me.zulu.video"}/u/${
      currentUser.value.username
    }/`
);

const showCopiedMessage = ref(false);
const handleCopyClicked = () => {
  showCopiedMessage.value = true;
  navigator.clipboard.writeText(profileLink.value);

  setTimeout(() => {
    showCopiedMessage.value = false;
  }, 3000);
};
</script>
