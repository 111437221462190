import { ref } from "vue";
import moment from "moment";

// Stream
export const stream = ref();
export const mimeType = ref();
export const isLoadingStream = ref(false);
export const facingMode = ref("user");

// Recording
let recorder;
let recordedChunks = [];
export const recordedStream = ref();
export const recordingUrl = ref();
export const isRecording = ref(false);
let startTime;
let endTime;
export const duration = ref();

export const startCameraStream = async () => {
  // Get preferred devices
  const videoDeviceId = localStorage.getItem("preferredVideoDeviceId");
  const audioDeviceId = localStorage.getItem("preferredAudioDeviceId");

  // Setup camera constraints
  const cameraConfig = { audio: true };
  cameraConfig.video = {
    width: {
      min: 720,
      ideal: 1280,
      max: 1280,
    },
  };

  // Set preferred devices
  if (videoDeviceId) {
    cameraConfig.video.deviceId = videoDeviceId;
  }
  if (audioDeviceId) {
    cameraConfig.audio = {
      deviceId: audioDeviceId,
    };
  }

  isLoadingStream.value = true;
  stream.value = await navigator.mediaDevices.getUserMedia(cameraConfig);
  isLoadingStream.value = false;

  console.log("stream", stream.value);

  // Set preferred device to current track devices
  stream.value.getTracks().forEach((track) => {
    setInputDevice(track);
  });

  document.addEventListener("visibilitychange", async function () {
    console.log("visibility Change", document.hidden);
    if (document.hidden && !isRecording.value) {
      cleanupCameraStream();
    }
  });
};

export const setInputDevice = (track) => {
  const kind = track.kind;
  const capabilities = track.getCapabilities();
  const deviceId = capabilities.deviceId;
  console.log("capabilities", capabilities);

  if (kind.includes("video")) {
    localStorage.setItem("preferredVideoDeviceId", deviceId);
    if (capabilities.facingMode?.length) {
      facingMode.value = capabilities.facingMode[0];
    }
  }
  if (kind.includes("audio")) {
    localStorage.setItem("preferredAudioDeviceId", deviceId);
  }
};

export const startRecording = () => {
  console.log("starting", stream.value);
  const videoTracks = stream.value.getVideoTracks();
  videoTracks.forEach((e) => console.log(e));

  // Get supported mime type
  const types = [
    "video/x-matroska;codecs=h264",
    "video/webm;codecs=vp9,codecs=opus",
    "video/webm;codecs=vp9,opus",
    "video/webm;codecs=vp9",
    "video/webm;codecs=vp8,codecs=opus",
    "video/webm;codecs=vp8,opus",
    "video/webm;codecs=vp8",
    "video/mpeg",
    "video/mp4",
    "video/mpeg4",
  ];

  for (const type of types) {
    const isSupported = MediaRecorder.isTypeSupported(type);
    console.log(`${type} ${isSupported}`);
    if (isSupported) {
      mimeType.value = type;
      break;
    }
  }

  // Start recorder
  recorder = new MediaRecorder(stream.value);
  recorder.start(1000);
  isRecording.value = true;

  recorder.ondataavailable = (event) => {
    // record new chunk
    recordedChunks.push(event.data);
  };

  startTime = new Date();
};

export const stopRecording = () => {
  console.log("stopping", stream.value);

  recorder.onstop = () => {
    isRecording.value = false;
    endTime = new Date();

    // Calculate duration
    const startTimeMoment = moment(startTime);
    const endTimeMoment = moment(endTime);
    duration.value = endTimeMoment.diff(startTimeMoment);

    recordedStream.value = new Blob(recordedChunks, {
      type: mimeType.value,
    });

    recordingUrl.value = window.URL.createObjectURL(recordedStream.value);
    console.log("blob url", recordingUrl.value);
  };

  console.log("recorded", recorder);

  // Stop recorder
  recorder.stop();
};

export const cleanupCameraStream = async () => {
  try {
    stream.value?.getVideoTracks().forEach((track) => {
      track.stop();
    });
    stream.value?.getAudioTracks().forEach((track) => {
      track.stop();
    });
  } catch (error) {
    console.error(error);
  }

  stream.value = null;
  facingMode.value = "user";
};

export const cleanupRecording = () => {
  recorder = null;
  recordedChunks = [];
  recordedStream.value = null;
  recordingUrl.value = null;
  mimeType.value = null;

  startTime = null;
  endTime = null;
  duration.value = null;
};

export const resetRecorder = async () => {
  console.log("resetting recorder");
  await cleanupCameraStream();
  cleanupRecording();
};
