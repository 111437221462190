<template>
  <div v-if="usersReady" class="flex pl-3">
    <!-- Horizontal -->
    <template
      v-if="
        type === 'horizontal' ||
        (type === 'square' && filteredUsers.length <= 1)
      "
    >
      <div class="flex flex-row-reverse relative">
        <div
          v-for="user in filteredUsers.slice(
            0,
            filteredUsers.length < displayLimit
              ? filteredUsers.length
              : displayLimit
          )"
          :key="user.id"
          class="flex rounded-full shadow border-solid border border-background-light-800 bg-background-dark-100 overflow-hidden"
          :class="{
            'h-14 w-14': size === 'large',
            'h-10 w-10': size === 'medium',
            'h-8 w-8': size === 'small',
            'h-6 w-6': size === 'extra-small',
            '-ml-3': type !== 'square',
          }"
        >
          <!-- Overlay -->
          <div
            class="absolute flex w-full h-full justify-center items-center"
          ></div>
          <div class="w-full h-full flex justify-center items-center">
            <div v-if="user.photoURL">
              <img
                class="object-cover"
                :src="user.photoURL"
                referrerpolicy="no-referrer"
              />
            </div>
            <div
              v-else
              class="text-white"
              :class="{
                '': size === 'large',
                'text-[1rem]': size === 'medium',
                'text-[0.6rem]': size === 'small',
                'text-[0.5rem]': size === 'extra-small',
              }"
            >
              {{ user.firstName?.[0] }}{{ user.lastName?.[0] }}
            </div>
          </div>
        </div>

        <div
          v-if="filteredUsers.length > displayLimit"
          class="absolute bottom-0 right-0 bg-white rounded-full"
        >
          <div class="text-[.6rem] text-black font-bold px-1">
            +{{ filteredUsers.length - displayLimit }}
          </div>
        </div>
      </div>
    </template>

    <!-- Square -->
    <template v-else-if="type === 'square' && filteredUsers.length > 1">
      <div
        class="flex flex-wrap relative bg-background-400 p-1"
        :class="{
          'h-16 w-16 rounded-2xl': size === 'large',
          'h-10 w-10 rounded-xl': size === 'medium',
          'h-8 w-8 rounded-lg': size === 'small',
          'h-6 w-6 rounded': size === 'extra-small',
        }"
      >
        <div
          v-for="user in filteredUsers.slice(
            0,
            filteredUsers.length < 4 ? filteredUsers.length : 4
          )"
          :key="user.id"
          class="flex rounded-full shadow border-solid border border-background-light-800 bg-background-dark-100 overflow-hidden h-1/2 aspect-square"
        >
          <!-- Overlay -->
          <div
            class="absolute flex w-full h-full justify-center items-center"
          ></div>
          <div class="w-full h-full flex justify-center items-center">
            <div v-if="user.photoURL">
              <img
                class="object-cover"
                :src="user.photoURL"
                referrerpolicy="no-referrer"
              />
            </div>
            <div
              v-else
              class="text-white"
              :class="{
                'text-[1rem]': size === 'large',
                'text-[.4rem]': size === 'medium',
                'text-[0.25rem]': size === 'small',
                'text-[0.18rem]': size === 'extra-small',
              }"
            >
              {{ user.firstName?.[0] }}{{ user.lastName?.[0] }}
            </div>
          </div>
        </div>

        <div
          v-if="filteredUsers.length > 4"
          class="absolute bottom-0 right-0 bg-white rounded-full"
        >
          <div class="text-[.6rem] text-black font-bold px-1">
            +{{ filteredUsers.length - 4 }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import { useStore } from "vuex";

// Props
const props = defineProps({
  users: Array,
  type: {
    type: String,
    default: "horizontal",
  },
  size: {
    type: String,
    default: "small",
  },
  displayLimit: {
    type: Number,
    default: 3,
  },
  excludeCurrentUser: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const filteredUsers = computed(() => {
  if (props.users.length > 1) {
    return props.users.filter(
      (e) => e?.id !== store.state.firebaseData.currentUser?.id
    );
  }
  return props.users;
});

const usersReady = computed(() => {
  return !filteredUsers.value.some((e) => !e?.id);
});
</script>
