import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faVideo,
  faDisplay,
  faUser,
  faUsers,
  faClose,
  faCirclePlus,
  faMessage,
  faAddressBook,
  faTrash,
  faCircleInfo,
  faPaperPlane,
  faStop,
  faArrowRightFromBracket,
  faEye,
  faEyeSlash,
  faHandshake,
  faSackDollar,
  faDollarSign,
  faCalendarDays,
  faStopwatch,
  faBell,
} from "@fortawesome/free-solid-svg-icons";

import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* add font awesome icon component */
export const FontAwesomeIconSetup = () => {
  library.add(
    faVideo,
    faDisplay,
    faUser,
    faUsers,
    faClose,
    faCirclePlus,
    faMessage,
    faAddressBook,
    faTrash,
    faCircleInfo,
    faPaperPlane,
    faStop,
    faArrowRightFromBracket,
    faEye,
    faEyeSlash,
    faHandshake,
    faGoogle,
    faSackDollar,
    faDollarSign,
    faCalendarDays,
    faStopwatch,
    faBell
  );

  return FontAwesomeIcon;
};
