<template>
  <div id="videoControls" class="relative w-full h-full relative">
    <div class="video-player bg-background-dark-900 h-full w-full">
      <!-- Video Player -->

      <video
        :class="{ fit: !fill, fill }"
        class="z-0"
        ref="videoElement"
        preload="metadata"
        :src="props?.source"
        :poster="props.poster"
        :autoplay="props.autoplay"
        :playsinline="props.playsinline"
        :muted="props.muted"
        :loop="props.loop"
        @error="handleError"
      ></video>
    </div>

    <!-- Controls -->
    <div
      class="absolute top-0 w-full h-full flex flex-col justify-between text-white z-10"
    >
      <!-- Loading Spinner -->
      <div
        v-if="isLoading"
        class="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center bg-black/50 animate-pulse"
      >
        <ion-spinner
          name="crescent"
          color="light"
          class="absolute h-24 w-24"
        ></ion-spinner>
      </div>

      <!-- Header -->
      <div class="flex justify-between p-3">
        <!-- Left -->
        <div></div>
        <!-- Right -->
        <button
          class="cursor-pointer pointer-events-auto rounded-full flex justify-center items-center h-8 w-8 bg-black/30 backdrop-blur-lg"
          @click="toggleFill()"
        >
          <ion-icon
            color="light"
            :icon="fill ? chevronCollapseOutline : chevronExpandOutline"
          ></ion-icon>
        </button>
      </div>

      <!-- Middle -->
      <button class="grow pointer-events-auto" @click="togglePlayback()">
        <ion-icon
          v-if="!isPlaying"
          :icon="play"
          class="text-8xl text-white/50"
        ></ion-icon>
      </button>

      <!-- Footer -->
      <div class="flex flex-col pointer-events-auto">
        <div
          class="flex w-full px-3 py-2 items-center backdrop-blur-sm bg-black/30"
        >
          <div class="flex">
            <button
              aria-label="play/pause"
              class="cursor-pointer rounded-full flex justify-center items-center h-8 w-8 bg-primary"
              @click="togglePlayback()"
            >
              <ion-icon
                color="light"
                :icon="isPlaying ? pause : play"
              ></ion-icon>
            </button>
          </div>
          <div class="grow w-full flex items-center text-sm font-medium px-3">
            {{ timeElapsedFormatted }}
            <template v-if="durationFormatted">
              <ion-range
                :value="timeElapsedProgress"
                aria-label="time slider"
                class="p-0 mx-6"
                @ion-knob-move-start="sliderStarted"
                @ion-knob-move-end="sliderEnded"
              ></ion-range>
              <div class="text-sm font-medium">{{ durationFormatted }}</div>
            </template>
          </div>
          <div class="flex justify-end">
            <div class="flex cursor-pointer ml-2" @click="toggleMute()">
              <ion-icon
                slot="icon-only"
                color="light"
                class="h-5 w-5"
                :icon="isMuted ? volumeMute : volumeHigh"
              ></ion-icon>
            </div>
            <div class="flex cursor-pointer ml-2" @click="fullscreen()">
              <ion-icon
                slot="icon-only"
                color="light"
                class="h-5 w-5"
                :icon="expand"
              ></ion-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, defineProps, defineExpose, onMounted } from "vue";
import {
  IonIcon,
  IonRange,
  IonSpinner,
  isPlatform,
  toastController,
} from "@ionic/vue";

import {
  play,
  pause,
  expand,
  volumeHigh,
  volumeMute,
  chevronExpandOutline,
  chevronCollapseOutline,
} from "ionicons/icons";
import moment from "moment";
import { getTimeString } from "@/utilities/time";

const props = defineProps({
  source: [Object, String],
  poster: String,
  fill: Boolean,
  loop: Boolean,
  autoplay: Boolean,
  muted: Boolean,
  playsinline: Boolean,
});

// Video Player
const videoElement = ref(null);

// const selectedMessage = computed(() => {
//   return store.state.firebaseData.selectedMessage;
// });

const isLoading = ref(false);
const isPlaying = ref(false);
const duration = ref(null);
const currentTime = ref(videoElement.value?.currentTime);

const durationFormatted = computed(() => {
  if (duration.value === Infinity) return "∞";
  const durationTime = moment(0);
  durationTime.set("second", duration.value);
  return getTimeString(durationTime);
});

const timeElapsedFormatted = computed(() => {
  const timeElapsed = moment(0);
  timeElapsed.set("second", currentTime.value);
  return getTimeString(timeElapsed);
});

const timeElapsedProgress = computed(() => {
  if (!duration.value) return 0;
  return (currentTime.value / duration.value) * 100;
});

const isSliderScrubbing = ref(false);
const sliderStarted = () => {
  isSliderScrubbing.value = true;
};
const sliderEnded = (event) => {
  isSliderScrubbing.value = false;
  const percentage = event.detail?.value / 100;
  const seconds = duration.value * percentage;
  // eslint-disable-next-line vue/no-mutating-props
  videoElement.value.currentTime = seconds;
};

// Mounted
onMounted(() => {
  // VIDEO EVENT LISTENERS

  // Loaded Metadata
  videoElement.value.addEventListener("loadedmetadata", () => {
    isLoading.value = false;
  });
  // Duration Change
  videoElement.value.addEventListener("durationchange", () => {
    duration.value = videoElement.value.duration;
  });
  // Time Update
  videoElement.value.addEventListener("timeupdate", () => {
    if (!isSliderScrubbing.value) {
      currentTime.value = videoElement.value?.currentTime;
    }
  });
  // Can Play
  videoElement.value.addEventListener("canplay", () => {
    isLoading.value = false;
  });
  // Waiting
  videoElement.value.addEventListener("waiting", () => {
    isLoading.value = true;
  });
  // Load Start
  videoElement.value.addEventListener("loadstart", () => {
    isLoading.value = true;
  });
  // Play
  videoElement.value.addEventListener("play", () => {
    console.log("play");
    isPlaying.value = true;
  });
  // Pause
  videoElement.value.addEventListener("pause", () => {
    console.log("pause");
    isPlaying.value = false;
  });
  // Ended
  videoElement.value.addEventListener("ended", () => {
    currentTime.value = 0;
  });
  // Volume Change
  videoElement.value.addEventListener("volumechange", () => {
    isMuted.value = videoElement.value.muted;
  });
  // Fullscreen Change
  videoElement.value.addEventListener("fullscreenchange", () => {
    fill.value = props.fill;
  });
});

const togglePlayback = () => {
  if (isPlaying.value) {
    videoElement.value.pause();
  } else {
    // eslint-disable-next-line vue/no-mutating-props
    videoElement.value.setAttribute("playsinline", true);
    // eslint-disable-next-line vue/no-mutating-props
    videoElement.value.removeAttribute("controls");

    videoElement.value.play();
  }
};

const isMuted = ref(false);
const toggleMute = () => {
  // eslint-disable-next-line vue/no-mutating-props
  videoElement.value.muted = !videoElement.value.muted;
};

const fill = ref(props.fill);
const toggleFill = () => {
  fill.value = !fill.value;
};

const fullscreen = async () => {
  fill.value = false;
  if (isPlatform("ios")) {
    videoElement.value.pause();
    videoElement.value.hasAttribute("playsinline")
      ? videoElement.value.removeAttribute("playsinline")
      : videoElement.value.setAttribute("playsinline", "");
    videoElement.value.play();
  } else {
    // console.dir(videoElement.value);
    const requestMethod =
      videoElement.value.requestFullscreen ??
      videoElement.value.webkitRequestFullScreen ??
      videoElement.value.mozRequestFullScreen ??
      videoElement.value.msRequestFullscreen;

    if (requestMethod) {
      requestMethod.apply(videoElement.value);

      videoElement.value.hasAttribute("playsinline")
        ? videoElement.value.removeAttribute("playsinline")
        : videoElement.value.setAttribute("playsinline", "");
    } else {
      const toast = await toastController.create({
        header: "Fullscreen not supported",
        message: "Your device/browser does not support fullscreen video",
        color: "danger",
        duration: 3000,
        position: "top",
      });
      await toast.present();
    }
  }
};

const handleError = (event) => {
  console.error(event);
};

defineExpose({
  isPlaying,
  togglePlayback,
});
</script>
<style>
#videoControls .video-player > video {
  position: absolute;
  width: 100%;
}

#videoControls .video-player > video.fill {
  height: 100%;
  object-fit: cover;
}

#videoControls .video-player > video.fit {
  height: 100%;
  object-fit: contain;

  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
}

#videoControls .video-player {
  position: relative;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

#videoControls #textContent a {
  color: white !important;
}
</style>
