<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="flex items-center">
        <ion-title>
          <div class="flex justify-center">Profile</div>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="flex justify-center">
        <div v-if="currentUser.id" class="max-w-screen-sm w-full">
          <div class="mt-4 flex flex-col items-center justify-center">
            <AvatarGroup
              v-if="currentUser?.id"
              :users="[currentUser]"
              :display-limit="2"
              size="large"
            />
            <ion-button fill="clear" size="small" @click="handleEditAvatar()">
              Change Avatar
            </ion-button>
            <ion-modal
              ref="changeAvatarRef"
              :is-open="isOpenChangeAvatar"
              @didDismiss="isOpenChangeAvatar = false"
            >
              <AvatarUpload
                :image="selectedAvatarImage"
                @close="isOpenChangeAvatar = false"
              ></AvatarUpload>
            </ion-modal>
          </div>
          <ion-list inset class="bg-white">
            <ion-item>
              <ion-input
                :value="userData.firstName"
                label="First Name"
                placeholder="Enter text"
                label-placement="floating"
                @ionInput="userData.firstName = $event.target.value"
                @ionBlur="handleBlur()"
              ></ion-input>
            </ion-item>

            <ion-item>
              <ion-input
                :value="userData.lastName"
                label="Last Name"
                placeholder="Enter text"
                label-placement="floating"
                @ionInput="userData.lastName = $event.target.value"
                @ionBlur="handleBlur()"
              ></ion-input>
            </ion-item>

            <ion-item>
              <ion-input
                :value="userData.displayName"
                label="Display Name"
                placeholder="Enter text"
                label-placement="floating"
                @ionInput="userData.displayName = $event.target.value"
                @ionBlur="handleBlur()"
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-input
                :value="userData.email"
                disabled
                label="Email"
                label-placement="floating"
              ></ion-input>
            </ion-item>
          </ion-list>

          <div class="flex justify-center">
            <ion-button
              v-if="currentUser.isSuperAdmin"
              fill="clear"
              @click="isOpenImpersonate = true"
            >
              Impersonate User
            </ion-button>
            <ion-alert
              :is-open="isOpenImpersonate"
              header="Impersonate User"
              sub-header="Enter a user ID to impersonate"
              :inputs="[
                {
                  type: 'text',
                  placeholder: 'User ID',
                  min: 1,
                  max: 100,
                },
              ]"
              :buttons="[
                { text: 'OK', handler: handleImpersonate },
                { text: 'Cancel' },
              ]"
              @didDismiss="isOpenImpersonate = false"
            ></ion-alert>
          </div>
          <div class="flex justify-center">
            <ion-button
              fill="clear"
              color="danger"
              @click="store.dispatch('firebaseData/signOut')"
            >
              Sign out
            </ion-button>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import AvatarGroup from "@/components/AvatarGroup";
import AvatarUpload from "./AvatarUpload.vue";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonInput,
  IonItem,
  IonList,
  IonButton,
  IonModal,
  IonAlert,
} from "@ionic/vue";
import { computed, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { db, auth } from "@/plugins/firebase";
import { promptForFileSelection, getFileBlobURLs } from "@/utilities/file";
import { sendRequest } from "@/utilities/api";

const store = useStore();

const currentUser = computed(() => store.state.firebaseData.currentUser);

const userData = reactive({ ...store.state.firebaseData.currentUser });
watch(currentUser, () => {
  Object.assign(userData, { ...store.state.firebaseData.currentUser });
});

const handleBlur = async () => {
  console.log("test", userData);
  await db.collection("users").doc(currentUser.value.id).update({
    firstName: userData.firstName,
    lastName: userData.lastName,
    displayName: userData.displayName,
  });
};

const isOpenImpersonate = ref(false);
const handleImpersonate = async (alertData) => {
  const userId = alertData[0];

  const response = await sendRequest(`/api/admin/impersonate/${userId}`, {
    method: "GET",
  });

  const responseBody = await response.json();

  await auth.signInWithCustomToken(responseBody.token);
  localStorage.setItem("impersonating", true);

  location.reload();
};

const isOpenChangeAvatar = ref(false);
const selectedAvatarImage = ref();
const handleEditAvatar = async () => {
  const files = await promptForFileSelection({ accept: "image/*" });
  const fileURLs = getFileBlobURLs(files);
  if (fileURLs.length) {
    selectedAvatarImage.value = fileURLs[0];
    isOpenChangeAvatar.value = true;
  }
};
</script>

<style scoped>
#zuluCoaching::part(native) {
  @apply bg-primary text-white;
}
</style>
