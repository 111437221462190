<template>
  <div class="flex flex-col h-full">
    <header class="sticky top-0 w-full z-50">
      <nav class="backdrop-blur-xl bg-white/75 border-gray-200 py-2.5">
        <div
          class="flex flex-col md:flex-row md:align-center justify-between px-3 py-1 md:px-6"
        >
          <!-- Logo -->
          <a
            href="#"
            class="flex items-center justify-center md:mx-8 mb-4 md:mb-0"
          >
            <img
              src="@/assets/full_logo.png"
              class="h-10 md:h-16"
              alt="Zulu Logo"
            />
          </a>
          <div v-if="!route.query?.target" class="flex items-center">
            <!-- Path (hide if 'target' is present in query -- free chat) -->
            <div
              class="flex w-full justify-center gap-6 md:gap-12 lg:gap-24 p-2 lg:px-8"
            >
              <div
                v-for="[index, element] in path.entries()"
                :key="element"
                class="flex flex-col justify-center items-center"
                :class="{
                  'opacity-25': element.complete,
                }"
              >
                <div
                  class="rounded-full w-10 h-10 flex justify-center items-center lg:text-lg font-bold mb-2"
                  :class="{
                    'bg-gray-200 text-black':
                      !element.complete && !element.current,
                    'bg-primary text-white':
                      element.complete || element.current,
                  }"
                >
                  {{ index + 1 }}
                </div>
                <div class="font-bold text-lg text-black">
                  {{ element.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="block w-40">&nbsp;</div>
        </div>
      </nav>
    </header>
    <router-view class="flex-grow h-min-full"></router-view>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { offer } from "./checkout";

const route = useRoute();

const path = computed(() => {
  const pathElements = [];
  pathElements.push({
    name: "Registration",
    slug: "checkout-register",
  });

  if (offer.value?.data()?.price !== 0) {
    pathElements.push({
      name: "Payment",
      slug: "checkout-payment",
    });
  }

  pathElements.push({
    name: "Confirmation",
    slug: "checkout-confirmation",
  });

  for (const pathElement of pathElements) {
    if (pathElement.slug !== route.name) {
      pathElement.complete = true;
    } else {
      pathElement.current = true;
      break;
    }
  }

  return pathElements;
});
</script>
