<template>
  <ion-page>
    <ion-header class="lg:bg-background-200">
      <ion-toolbar class="flex items-center">
        <ion-buttons slot="start">
          <ion-button @click="handleCancel()"> Cancel </ion-button>
        </ion-buttons>
        <ion-title>
          <div class="flex justify-center">Upload Image</div>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="flex justify-center items-center w-full h-full bg-black">
        <!-- Loading Spinner -->
        <div
          v-if="isLoading"
          class="z-10 absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center bg-black/75 animate-pulse"
        >
          <ion-spinner
            name="crescent"
            color="light"
            class="absolute h-24 w-24"
          ></ion-spinner>
          <ion-icon
            :icon="cloudUploadOutline"
            class="text-3xl text-light"
          ></ion-icon>
        </div>
        <div
          v-show="!previewImage"
          class="relative h-full w-full flex flex-col items-center justify-center"
        >
          <img class="w-full" :src="props.image" ref="imageRef" />
          <ion-button
            @click="handleCrop()"
            shape="round"
            class="absolute bottom-0 m-4"
          >
            Crop
          </ion-button>
        </div>
        <div
          v-if="previewImage"
          class="absolute top-0 h-full w-full flex flex-col items-center justify-center gap-4 bg-background-dark-800"
        >
          <img :src="previewImage" class="w-2/3 rounded-full" />
          <div class="flex justify-center gap-2">
            <ion-button @click="handleUndoCrop()" shape="round" color="dark">
              Undo Crop
            </ion-button>
            <ion-button @click="handleUpload()" shape="round">
              Upload
            </ion-button>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonIcon,
  IonSpinner,
  IonButtons,
  IonButton,
  toastController,
} from "@ionic/vue";
import { defineEmits, defineProps, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { cloudUploadOutline } from "ionicons/icons";
import { db, storage } from "@/plugins/firebase";

const props = defineProps(["image"]);
const emit = defineEmits(["close"]);

const store = useStore();

const currentUser = computed(() => store.state.firebaseData.currentUser);

let cropper = null;
const imageRef = ref(null);
const previewImage = ref();

const isLoading = ref(false);
const uploadImage = async (imageToUpload) => {
  isLoading.value = true;

  try {
    // Upload avatar
    const uploadDestination = `profiles/${currentUser.value.id}/avatar.png`;
    const avatarRef = storage.child(uploadDestination);
    await avatarRef.put(imageToUpload);

    // Update profile
    await db
      .collection("users")
      .doc(currentUser.value.id)
      .update({ photoURL: await avatarRef.getDownloadURL() });
  } catch {
    const toast = await toastController.create({
      header: "Failed to upload image",
      message: "Please try reselecting and reuploading your image",
      color: "danger",
      duration: 4000,
      position: "top",
    });
    await toast.present();
  }
  isLoading.value = false;

  emit("close");
};
const handleUpload = async () => {
  cropper
    .getCroppedCanvas({ width: 200, height: 200 })
    .toBlob(uploadImage, "image/png");
};
const handleCrop = () => {
  previewImage.value = cropper.getCroppedCanvas().toDataURL();
};
const handleUndoCrop = () => {
  previewImage.value = null;
};

const handleCancel = () => {
  emit("close");
};

const initCropper = () => {
  cropper = new Cropper(imageRef.value, {
    aspectRatio: 1,
    viewMode: 2,
    background: false,
  });
};

onMounted(() => {
  initCropper();
});
</script>
