<template>
  <div>
    <div
      v-if="isImpersonating"
      class="h-[2.5rem] w-full flex justify-center items-center bg-danger text-white"
    >
      You are impersonating&nbsp;<span class="font-bold">{{
        currentUser?.displayName
      }}</span>
    </div>
    <ion-app :style="isImpersonating ? 'top:2.5rem;' : ''">
      <ion-router-outlet :animated="isLargeScreen" />
    </ion-app>
  </div>
</template>

<script setup>
import { IonApp, IonRouterOutlet, getPlatforms } from "@ionic/vue";
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
const breakpoints = useBreakpoints(breakpointsTailwind);
const isLargeScreen = breakpoints.greaterOrEqual("lg");

const store = useStore();

const currentUser = computed(() => store.state.firebaseData.currentUser);
const isImpersonating = localStorage.getItem("impersonating") === "true";

console.log("platforms:", getPlatforms());
onMounted(() => {
  store.dispatch("firebaseData/bindCurrentUser");
});
</script>
