import { db, auth, storage } from "../plugins/firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import { firestoreAction } from "@xquick-code/vuexfire";
import router from "@/router";
import moment from "moment";

export default {
  namespaced: true,
  state() {
    return {
      currentUser: {},
      currentUserMetadata: {},
      currentUserMeProfile: {},

      groups: [],
      groupsReady: null,

      selectedGroup: {},
      selectedGroupReady: false,

      selectedGroupMessages: [],
      selectedMessage: null,

      unreadMessageNotifications: [],
      allNotifications: [],
      notificationsInitialized: false,
    };
  },
  getters: {
    isGroupCreatedByCurrentUser(state) {
      return state.currentUser?.id === state.selectedGroup?.createdBy?.id;
    },
    unreadNotifications(state) {
      return state.unreadMessageNotifications?.filter((e) => !e.read);
    },
    unreadNotificationsCount(state) {
      return state.unreadMessageNotifications?.filter((e) => !e.read)?.length;
    },
  },
  mutations: {
    CLEAR_GROUP(state) {
      state.selectedGroup = {};
    },
    SET_GROUP_READY(state, value) {
      state.selectedGroupReady = value;
    },
    SET_GROUPS_READY(state, value) {
      state.groupsReady = value;
    },
    SET_NOTIFICATIONS_INITIALIZED(state) {
      state.notificationsInitialized = true;
    },
    SET_SELECTED_GROUP(state, group) {
      state.selectedGroup = group;
    },
    SET_SELECTED_MESSAGE(state, message) {
      state.selectedMessage = message;
    },
  },
  actions: {
    async signOut({ dispatch }, options) {
      localStorage.setItem("impersonating", false);

      await auth.signOut();
      await dispatch("bindCurrentUser");
      window.location.href = options?.redirectURL
        ? `/auth/login?redirect=${options.redirectURL}`
        : "/auth/login";
    },

    navigateToGroup({ state }) {
      const group = state.selectedGroup;
      if (group) {
        if (group.type === "meeting") {
          router.navigate({
            name: "group",
            params: { groupId: state.selectedGroup?.id },
          });
        } else {
          router.navigate({
            name: "group",
            params: { groupId: state.selectedGroup?.id },
          });
        }
      } else {
        router.push({
          name: "groups-list",
        });
      }
    },

    bindNotifications: firestoreAction(async ({ bindFirestoreRef, commit }) => {
      // Unread Notifications
      await bindFirestoreRef(
        "unreadMessageNotifications",
        db
          .collection("users")
          .doc(auth.currentUser.uid)
          .collection("notifications")
          .where("type", "==", "NEW_GROUP_MESSAGE")
          .where("read", "==", false)
      );
      // All Notifications
      // await bindFirestoreRef(
      //   "allNotifications",
      //   db
      //     .collection("users")
      //     .doc(auth.currentUser.uid)
      //     .collection("notifications")
      //     .limit(50)
      //     .orderBy("createdAt", "desc")
      // );

      console.log("notifications initialized");
      commit("SET_NOTIFICATIONS_INITIALIZED");
    }),

    bindGroups: firestoreAction(async ({ bindFirestoreRef, state, commit }) => {
      if (state.groupsReady === "pending") return;
      commit("SET_GROUPS_READY", "pending");

      // return the promise returned by `bindFirestoreRef`

      await bindFirestoreRef(
        "groups",
        db
          .collection("groups")
          .where(
            "members",
            "array-contains",
            db.collection("users").doc(auth.currentUser.uid)
          )
          .where("type", "in", ["group", "paid-group", "system-announcements"]),
        { wait: true }
      );

      // if (rootState.route.params?.groupId) {
      //   dispatch("bindSelectedGroup", {
      //     groupId: rootState.route.params?.groupId,
      //   });
      // }
      commit("SET_GROUPS_READY", "complete");
    }),

    bindCurrentUser: firestoreAction(
      async ({ bindFirestoreRef, unbindFirestoreRef }) => {
        if (auth.currentUser) {
          await bindFirestoreRef(
            "currentUser",
            db.collection("users").doc(auth.currentUser.uid)
          );
          await bindFirestoreRef(
            "currentUserMetadata",
            db
              .collection("users")
              .doc(auth.currentUser.uid)
              .collection("metadata")
              .doc("private")
          );
          return bindFirestoreRef(
            "currentUserMeProfile",
            db
              .collection("users")
              .doc(auth.currentUser.uid)
              .collection("pages")
              .doc("profile")
          );
        } else {
          return unbindFirestoreRef("currentUser");
        }
      }
    ),

    bindSelectedGroup: firestoreAction(
      async (
        { bindFirestoreRef, dispatch, state, commit },
        { groupId, type }
      ) => {
        // ignore group already set
        if (state.selectedGroup?.id === groupId) return;

        // clear group and navigate to root if null
        if (!groupId) {
          commit("CLEAR_GROUP");
          await dispatch("navigateToGroup");
          return;
        }

        await commit("SET_GROUP_READY", false);

        /* 
          This whole next block is a workaround because vuefire for Vue 3 is a piece of shit...
          Right now, vuefire doesn't seem to be able to handle resolving references two levels deep
          when a document is bound in two separate calls around the same time (may not be time dependent, idk).
          So in the scenario where a group is queried when the group viewer page loads and the app is also loading
          the groups list at the same time (and the doc reference is in both, which is almost always the case except
          for meeting groups), the members, createdBy, and other reference fields aren't appropriately attached...
          So for now, just going to make meetings be handled with the separate query mechanism, while everything else
          will just try to find the entry in the list of groups... pls hlp
        */

        if (type === "meeting") {
          // bind selectedGroup
          await bindFirestoreRef(
            "selectedGroup",
            db.collection("groups").doc(groupId)
          );
        } else {
          // fetch whole group data set
          if (!state.groups.length) {
            await dispatch("bindGroups");
          }

          let foundGroup = state.groups?.find((e) => e.id === groupId);
          if (!foundGroup) {
            await bindFirestoreRef(
              "selectedGroup",
              db.collection("groups").doc(groupId)
            );
            foundGroup = state.selectedGroup;
          }

          await commit("SET_SELECTED_GROUP", foundGroup);
        }

        await dispatch("bindSelectedGroupMessages");

        return state.selectedGroup;
      }
    ),

    bindSelectedGroupMessages: firestoreAction(
      async ({ bindFirestoreRef, state, commit }) => {
        // return the promise returned by `bindFirestoreRef`
        await bindFirestoreRef(
          "selectedGroupMessages",
          db
            .collection("groups")
            .doc(state.selectedGroup.id)
            .collection("messages")
            .orderBy("sentAt", "desc")
        );

        await commit("SET_GROUP_READY", true);
      }
    ),

    async selectMessage(context, message) {
      router.replace({
        query: { selectedMessageId: message?.id },
        routerDirection: "none",
      });

      await context.commit("SET_SELECTED_MESSAGE", message);
      context.dispatch("markMessageRead");
    },

    async createNewMessage(context) {
      const selectedGroup = context.state.selectedGroup;

      // Get new message doc
      const messagesCollectionRef = db
        .collection("groups")
        .doc(selectedGroup.id)
        .collection("messages");
      const newMessage = messagesCollectionRef.doc();

      if (
        ["screen", "audio", "camera"].includes(
          context.rootState.video.player.recordingSource
        )
      ) {
        // Calculate duration
        const startTime = moment(context.rootState.video.startTime);
        const endTime = moment(context.rootState.video.endTime);
        const duration = endTime.diff(startTime);

        let upload = context.rootState.video.recordedStream;

        // Upload video
        const uploadDestination = `content/${newMessage.id}`;
        await storage.child(`${uploadDestination}/upload`).put(upload);

        await storage
          .child(`${uploadDestination}/poster.jpg`)
          .put(context.rootState.video.recordedStreamPoster);

        // Create message
        await newMessage.set({
          content: `${uploadDestination}/upload`,
          group: db.collection("groups").doc(selectedGroup.id),
          poster: `content/${newMessage.id}/poster.jpg`,
          contentDuration: duration,
          sentAt: firebase.firestore.FieldValue.serverTimestamp(),
          sentBy: db.collection("users").doc(context.state.currentUser.id), // TODO: remove hardcoded single user id
          status: !context.rootState.video.supportedMimeType.includes("mp4")
            ? "needs conversion"
            : "complete",
          type:
            context.rootState.video.player.recordingSource === "audio"
              ? "audio"
              : "video",
        });
      } else if (context.rootState.video.source === "text") {
        const text = context.rootState.video.player.src;

        // Create message
        await newMessage.set({
          content: text,
          group: db.collection("groups").doc(selectedGroup.id),
          contentDuration: Math.max((text.length / 5 / 183) * 60 * 1000, 3000), // (total chars / avg 5 chars per word / avg wpm) * 60 sec * 1000 ms -- fallback to min 3 seconds
          sentAt: firebase.firestore.FieldValue.serverTimestamp(),
          sentBy: db.collection("users").doc(context.state.currentUser.id), // TODO: remove hardcoded single user id
          status: "complete",
          type: "text",
        });
      }
    },

    async markMessageRead(context, payload) {
      const selectedMessage = payload?.message ?? context.state.selectedMessage;
      const messageNotification = context.state.unreadMessageNotifications.find(
        (e) => e.message?.id === selectedMessage.id
      );

      if (messageNotification) {
        await db
          .collection("users")
          .doc(auth.currentUser.uid)
          .collection("notifications")
          .doc(messageNotification.id)
          .update({ read: true });
      }
    },
  },
};
