<template>
  <ion-page>
    <ion-router-outlet :animated="isLargeScreen"></ion-router-outlet>
  </ion-page>
</template>

<script setup>
import { IonRouterOutlet, IonPage } from "@ionic/vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
const breakpoints = useBreakpoints(breakpointsTailwind);
const isLargeScreen = breakpoints.greaterOrEqual("lg");
</script>
