<template>
  <ion-page>
    <ion-header class="flex justify-center border-b">
      <ion-toolbar class="flex items-center lg:max-w-screen-sm bg-transparent">
        <ion-buttons slot="start">
          <ion-back-button
            :default-href="isSetup ? '/earn/checklist' : '/earn/configure'"
          ></ion-back-button>
        </ion-buttons>
        <ion-title
          >{{ !isUserSubscribed ? "Select" : "Manage" }} Plan</ion-title
        >
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-loading :is-open="isLoading"></ion-loading>
      <div class="flex flex-col items-center">
        <div
          class="max-w-screen-sm w-full flex flex-col p-4 items-center gap-8"
        >
          <!-- Header -->
          <section class="flex flex-col gap-2">
            <div class="flex flex-col items-center text-center px-4 py-3 gap-4">
              <div class="text-xl font-bold">
                {{ !isUserSubscribed ? "Select a" : "Manage" }} pricing plan
              </div>

              <p class="font-semibold">
                We currently only offer one product plan, but more plans with
                better features are coming
                <span class="font-bold text-primary italic">very soon</span>!
              </p>
            </div>
          </section>

          <section class="w-full flex flex-col gap-4">
            <!-- Basic -->
            <button
              class="relative flex rounded-xl bg-gray-100 px-4 py-3 gap-3 cursor-pointer w-full border-4 border-solid border-primary"
            >
              <div class="absolute -top-3 left-0 flex justify-center w-full">
                <ion-badge
                  v-if="isUserSubscribed || !stripeInfo?.hasRedeemedFreeTrial"
                >
                  <template v-if="stripeInfo?.plan === 'basic'">
                    Current Plan
                  </template>
                  <template v-else-if="!stripeInfo?.hasRedeemedFreeTrial">
                    1 month free trial
                  </template>
                </ion-badge>
              </div>
              <div class="flex flex-col w-full gap-3">
                <div class="flex justify-between items-center w-full">
                  <div class="flex items-center gap-3">
                    <ion-checkbox
                      disabled
                      :checked="true"
                      aria-label="basic selection"
                    ></ion-checkbox>
                    <div class="font-bold text-2xl">Basic</div>
                  </div>
                  <div class="flex flex-col items-end">
                    <div class="font-bold">
                      <span class="text-2xl text-primary">$10</span>/mo.
                    </div>
                    <div class="text-sm font-medium">15% commission*</div>
                  </div>
                </div>

                <p class="font-bold">
                  Perfect for someone who is just getting started.
                </p>

                <div class="ml-10 text-sm text-start">
                  <ul class="flex flex-wrap">
                    <li class="md:w-1/2 w-full p-1">
                      <ion-icon :icon="checkmark"></ion-icon> Customizable
                      offers
                    </li>
                    <li class="md:w-1/2 w-full p-1">
                      <ion-icon :icon="checkmark"></ion-icon> Basic time credits
                    </li>
                    <li class="md:w-1/2 w-full p-1">
                      <ion-icon :icon="checkmark"></ion-icon> Secure billing
                    </li>
                    <li class="md:w-1/2 w-full p-1">
                      <ion-icon :icon="checkmark"></ion-icon> Automated
                      onboarding
                    </li>
                  </ul>
                </div>
              </div>
            </button>

            <!-- Pro -->
            <button
              disabled
              class="relative flex rounded-xl bg-gray-100 px-4 py-3 gap-3 cursor-not-allowed w-full border-4 border-solid border-gray-300 opacity-50"
            >
              <div class="absolute -top-3 left-0 flex justify-center w-full">
                <ion-badge color="dark" class="bg-gray-500">
                  Coming Soon
                </ion-badge>
              </div>
              <div class="flex flex-col w-full gap-3">
                <div class="flex justify-between items-center w-full">
                  <div class="flex items-center gap-1 lg:gap-3">
                    <ion-checkbox
                      disabled
                      aria-label="pro selection"
                    ></ion-checkbox>
                    <div class="font-bold text-2xl">Pro</div>
                  </div>
                  <div class="flex flex-col items-end">
                    <div class="font-bold">
                      <span class="text-2xl text-primary">$25</span>/mo.
                    </div>
                    <div class="text-sm font-medium">15% commission*</div>
                  </div>
                </div>

                <p class="font-bold">
                  Sell subscription offers to your clients!
                </p>

                <div class="ml-10 text-sm text-start">
                  <ul class="flex flex-wrap">
                    <li class="w-full p-1">
                      <ion-icon :icon="checkmark"></ion-icon> Subscription
                      offers (monthly/weekly)
                    </li>
                    <li class="md:w-1/2 w-full p-1">
                      <ion-icon :icon="checkmark"></ion-icon> Time credit
                      expiration
                    </li>
                    <li class="md:w-1/2 w-full p-1">
                      <ion-icon :icon="checkmark"></ion-icon> Free offers
                    </li>
                  </ul>
                </div>
              </div>
            </button>

            <div
              class="italic text-xs text-gray-500 font-medium text-center mt-4"
            >
              * We only take a commission when someone purchases an offer from
              you.
            </div>
          </section>

          <section v-if="isUserSubscribed" class="w-full text-center">
            Or, you can
            <button
              @click="isOpenCancelPlanAlert = true"
              fill="clear"
              class="text-primary underline"
            >
              cancel your plan
            </button>
            <ion-alert
              :is-open="isOpenCancelPlanAlert"
              header="Are you sure?"
              sub-header="Clients will be unable to purchase offers from you"
              :buttons="[
                {
                  text: 'OK',
                  handler: () => {
                    handleSelectPlan('cancel');
                  },
                },
                { text: 'Cancel' },
              ]"
              @didDismiss="isOpenCancelPlanAlert = false"
            ></ion-alert>
          </section>
        </div>
        <div
          class="max-w-screen-sm sticky bottom-0 w-full backdrop-blur-lg bg-white/25 p-3"
        >
          <ion-button
            :disabled="
              stripeInfo?.plan === 'basic' && stripeInfo?.status !== 'cancelled'
            "
            @click="handleSelectPlan('basic')"
            class="w-full"
            >Select Plan</ion-button
          >
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed } from "vue";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonBackButton,
  IonButtons,
  IonButton,
  IonBadge,
  IonCheckbox,
  IonLoading,
  IonAlert,
  IonIcon,
  onIonViewWillEnter,
  toastController,
  useIonRouter,
} from "@ionic/vue";
import { checkmark } from "ionicons/icons";
import { sendRequest } from "@/utilities/api";
import { db, auth } from "@/plugins/firebase";
import { useRoute } from "vue-router";

const route = useRoute();
const router = useIonRouter();
const isSetup = computed(() => route.name === "setup-billing");

const isLoading = ref(false);
const handleSelectPlan = async (plan) => {
  isLoading.value = true;
  // TODO: hit api endpoint that sets up the stripe subscription process
  const response = await sendRequest(
    "/api/integrations/stripe/subscription/change",
    { method: "POST", body: { plan } }
  );

  const responseBody = await response.json();
  isLoading.value = false;

  if (responseBody.status === "redirect") {
    window.location.href = responseBody.url;
  } else {
    const toast = await toastController.create({
      message: responseBody.message,
      color: responseBody.status === "error" ? "danger" : "primary",
      duration: 4000,
      position: "top",
    });

    await toast.present();

    router.back();
  }
};

const isOpenCancelPlanAlert = ref(false);

const isUserSubscribed = computed(
  () => stripeInfo.value?.plan && stripeInfo.value?.status !== "cancelled"
);
const stripeInfo = ref();
onIonViewWillEnter(async () => {
  stripeInfo.value = (
    await db
      .collection("users")
      .doc(auth.currentUser.uid)
      .collection("stripe")
      .doc("private")
      .get()
  ).data();
});
</script>

<style scoped>
ion-toolbar {
  --background: transparent;
}
</style>
