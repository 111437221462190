import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store.js";
import { auth } from "@/plugins/firebase";

import "./index.css";
import "./theme/";

import { installPlugins } from "@/plugins/index";
// import "./registerServiceWorker";

let app = null;

auth.onAuthStateChanged(async () => {
  app = createApp(App).use(router);

  // Vuex
  app.use(store);

  installPlugins(app);

  router.isReady().then(() => {
    app.mount("#app");
  });
});
