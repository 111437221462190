<template>
  <div>
    <!-- Screen Share Button -->
    <button
      class="cursor-pointer rounded-full h-10 w-10 flex hover:bg-background-300 justify-center items-center gap-2 text-gray-400 hover:text-gray-500 font-bold text-lg"
      @click="setOpenCompose(true)"
    >
      <ion-icon slot="icon-only" class="h-6 w-6" :icon="desktop"></ion-icon>
    </button>

    <!-- Modal -->
    <ion-modal
      ref="composeModal"
      @ion-modal-did-dismiss="setOpenCompose(false)"
    >
      <ion-page id="composeWindow">
        <ion-content fullscreen class="bg-black">
          <div
            class="relative w-full h-full overflow-hidden flex justify-center items-center bg-black"
            :class="{ 'bg-red-600 animate-pulse': isRecording }"
          >
            <template v-if="!recordingUrl">
              <div
                class="w-full h-full flex flex-col justify-center items-center"
              >
                <div
                  v-if="countdown"
                  class="text-8xl text-white font-bold text-center my-4"
                >
                  {{ countdown }}
                </div>
                <template v-else>
                  <div class="flex flex-col h-full">
                    <div class="h-full"></div>
                    <button
                      class="rounded-3xl py-6 px-12 text-6xl text-white font-bold bg-white/10"
                      @click="
                        isRecording ? toggleRecording() : startScreenStream()
                      "
                    >
                      {{ isRecording ? "Stop" : "Start" }}
                    </button>
                    <div class="h-full flex items-end justify-center p-4">
                      <div
                        v-if="!isRecording"
                        class="flex items-center gap-2 bg-white/10 p-2 rounded-full"
                      >
                        <ion-toggle
                          aria-label="Microphone"
                          :checked="useMicrophone"
                          @ionChange="handleToggleMicrophone"
                        ></ion-toggle>

                        <div
                          class="mr-1"
                          :class="{
                            'opacity-50 pointer-events-none': !useMicrophone,
                          }"
                        >
                          <DeviceSelection audio>
                            <button class="flex">
                              <div
                                class="text-white font-bold"
                                :class="{
                                  'opacity-50 pointer-events-none':
                                    !useMicrophone,
                                }"
                              >
                                Microphone
                              </div>
                              <ion-icon
                                class="text-white/50 ml-2"
                                :icon="chevronDown"
                              >
                              </ion-icon>
                            </button>
                          </DeviceSelection>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </template>

            <template v-else>
              <div class="relative flex flex-col w-full h-full bg-black">
                <div class="w-full h-full rounded-b-xl">
                  <VideoPlayer
                    class="w-full h-full overflow-hidden rounded-b-xl"
                    :source="recordingUrl"
                  />
                </div>
                <div class="w-full flex justify-between p-3 pt-4">
                  <button
                    class="rounded-lg text-white bg-gray-900 font-semibold py-2 px-4 flex items-center gap-1"
                    @click="handleRedo()"
                  >
                    <ion-icon :icon="refresh" />

                    Redo
                  </button>
                  <button
                    class="rounded-lg bg-gradient-to-r from-primary to-secondary shadow-lg shadow-primary text-white text-lg font-bold py-2 px-4 flex items-center gap-2"
                    @click="handleSendMessage()"
                  >
                    Send
                    <ion-icon :icon="send" />
                  </button>
                </div>

                <div
                  class="absolute w-full h-full bg-black/75 backdrop-blur-lg flex flex-col items-center justify-center"
                  @click.stop
                  v-if="isSending"
                >
                  <CircleProgress
                    :percent="uploadProgress"
                    :is-gradient="true"
                    :size="120"
                    :gradient="{
                      angle: 90,
                      startColor: 'var(--ion-color-primary)',
                      stopColor: 'var(--ion-color-secondary)',
                    }"
                    >{{ uploadProgress }}</CircleProgress
                  >
                  <div class="text-white font-bold text-xl mt-4">Uploading</div>
                </div>
              </div>
            </template>

            <!-- Header -->
            <div
              class="absolute top-0 left-0 safe-t p-2 w-full flex justify-between"
            >
              <button @click="setOpenCompose(false)">
                <ion-icon
                  :icon="closeOutline"
                  class="text-2xl text-white backdrop-blur-lg bg-black/50 hover:bg-black/40 rounded-full p-1"
                ></ion-icon>
              </button>
              <div class="text-white font-semibold text-lg">Record Screen</div>
              <div class="w-10"></div>
            </div>
          </div>
        </ion-content>
      </ion-page>
    </ion-modal>
  </div>
</template>

<script setup>
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import VideoPlayer from "@/components/VideoPlayer";
import DeviceSelection from "./DeviceSelection";

import { ref } from "vue";
import { IonModal, IonContent, IonPage, IonIcon, IonToggle } from "@ionic/vue";
import {
  closeOutline,
  desktop,
  send,
  refresh,
  chevronDown,
} from "ionicons/icons";
import {
  startScreenStream,
  resetRecorder,
  isRecording,
  recordingUrl,
  recordedStream,
  startRecording,
  stopRecording,
  duration,
  countdown,
} from "./screenRecorder";
import { db, storage } from "@/plugins/firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import { useStore } from "vuex";

const store = useStore();

// Record
const toggleRecording = () => {
  if (!isRecording.value) {
    startRecording();
  } else {
    stopRecording();
  }
};

// Send
const isSending = ref();
const uploadProgress = ref();
const handleSendMessage = async () => {
  isSending.value = true;

  try {
    const selectedGroup = store.state.firebaseData.selectedGroup;
    // Get new message doc
    const messagesCollectionRef = db
      .collection("groups")
      .doc(selectedGroup.id)
      .collection("messages");
    const newMessage = messagesCollectionRef.doc();

    // Upload video
    let upload = recordedStream.value;
    const uploadDestination = `content/${newMessage.id}`;
    const uploadFileTask = storage
      .child(`${uploadDestination}/upload`)
      .put(upload);
    // Track progress updates
    uploadFileTask.on("state_changed", (snapshot) => {
      uploadProgress.value =
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    });
    await uploadFileTask;
    // Create message
    await newMessage.set({
      content: `${uploadDestination}/upload`,
      group: db.collection("groups").doc(selectedGroup.id),
      contentDuration: duration.value,
      sentAt: firebase.firestore.FieldValue.serverTimestamp(),
      sentBy: db
        .collection("users")
        .doc(store.state.firebaseData.currentUser.id), // TODO: remove hardcoded single user id
      status: "needs conversion",
      type: "video",
    });
  } catch (error) {
    console.error(error);
  }
  isSending.value = false;
  setOpenCompose(false);
};

// Redo
const handleRedo = () => {
  resetRecorder();
};

const composeModal = ref(null);
const setOpenCompose = async (value) => {
  value
    ? composeModal.value?.$el?.present()
    : composeModal.value?.$el?.dismiss();
  if (!value) {
    // Cleanup stream
    await resetRecorder();
  }
};

// Use Microphone
const getStoredMicrophonePreference = () => {
  const useMicrophoneForScreen = localStorage.getItem("useMicrophoneForScreen");
  if (useMicrophoneForScreen) {
    return useMicrophoneForScreen === "true";
  }
  // Default to true if not selected yet by user
  return true;
};
const useMicrophone = ref(getStoredMicrophonePreference());
const handleToggleMicrophone = (event) => {
  useMicrophone.value = event.target.checked;
  localStorage.setItem("useMicrophoneForScreen", event.target.checked);
};
</script>

<style scoped>
.reflected {
  transform: scale(-1, 1);
}
</style>
