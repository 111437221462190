import { db } from "../plugins/firebase";
import { firestoreAction } from "@xquick-code/vuexfire";

export default {
  namespaced: true,
  state() {
    return {
      page: {},
      user: {},
    };
  },
  getters: {},
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    getProfile: firestoreAction(
      async ({ bindFirestoreRef, commit }, username) => {
        console.log("test");
        // Get user by username
        const usersRef = await db
          .collection("users")
          .where("username", "==", username)
          .get();

        const userRef = usersRef.docs[0];
        if (!userRef) {
          throw new Error("User does not exist");
        }

        const user = await usersRef.docs[0]?.data();

        console.log("user", user);
        commit("SET_USER", user);

        return bindFirestoreRef(
          "page",
          db
            .collection("users")
            .doc(userRef.id)
            .collection("pages")
            .doc("profile")
        );
      }
    ),
  },
};
