<template>
  <div class="lg:ml-1">
    <div v-if="groups?.length" class="flex flex-col">
      <ion-list lines="none" class="bg-transparent p-2 gap-2">
        <group-list-item
          :group="group"
          v-for="group in groups"
          :key="group.id"
        ></group-list-item>
      </ion-list>
    </div>

    <div v-else class="flex flex-col items-center">
      <div
        class="flex flex-col justify-center items-center min-h-48 opacity-25 py-12 px-6 gap-2"
      >
        <ion-icon :icon="chatbubblesOutline" class="text-5xl my-2"></ion-icon>
        <div class="font-bold text-xl">No conversations</div>
        <div class="text-lg leading-tight text-center max-w-[275px]">
          Sell someone an offer or buy one from someone else to get chatting
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import GroupListItem from "./GroupListItem";
import { computed } from "vue";
import { useStore } from "vuex";
import { IonList, IonIcon } from "@ionic/vue";
import { chatbubblesOutline } from "ionicons/icons";
// Store
const store = useStore();

// Computed
const groups = computed(() => {
  const groupsList = store.state.firebaseData.groups;
  const groupsCopy = [...groupsList];

  // sort by last message timestamp (or createdAt time for new groups)
  const groupsSorted = groupsCopy.sort((a, b) => {
    const compareTimestampA = a.latestMessageSentAt ?? a.createdAt;
    const compareTimestampB = b.latestMessageSentAt ?? b.createdAt;

    return compareTimestampA < compareTimestampB ? 1 : -1;
  });

  return groupsSorted;
});
</script>

<style scoped></style>
