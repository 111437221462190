<template>
  <!-- Video Recording Element -->
  <div
    :id="props.message?.id"
    class="max-w-screen-sm flex gap-2 w-full"
    :class="{ 'flex-row-reverse': sender.id === currentUser.id }"
  >
    <div
      class="w-full bg-white text-black cursor-pointer h-full border border-solid border-4 rounded-2xl overflow-hidden shadow-lg shadow-black/10 lg:shadow-xl lg:shadow-black/10 flex flex-col relative"
      :class="{
        'border-white': selectedMessage !== props.message.id,
        'border-primary': selectedMessage === props.message.id,
      }"
      @click="selectMessage()"
    >
      <!-- Header -->
      <div class="flex p-2 justify-between">
        <!-- Left -->
        <div class="flex flex-col shrink-1 overflow-hidden">
          <div class="flex items-center gap-1 w-full">
            <!-- Notification Indicator -->
            <div v-if="isUnread" class="relative flex h-3 w-3 mx-1">
              <span
                class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-50"
              ></span>
              <span
                class="relative inline-flex rounded-full h-3 w-3 bg-primary"
              ></span>
            </div>

            <AvatarGroup :users="[sender]" :display-limit="2" size="medium" />
            <div class="overflow-hidden">
              <div class="text-sm font-bold truncate">
                {{ sender.displayName }}
              </div>
              <div class="text-xs" v-if="props.message?.sentAt">
                {{ moment(props.message.sentAt.toDate()).fromNow() }}
              </div>
            </div>
          </div>
        </div>
        <!-- Right -->
        <div class="flex gap-2 shrink-0">
          <div class="flex items-center gap-1">
            <button
              v-if="isUnread"
              class="py-1 rounded-full py-1 px-3 bg-violet-100 tracking-tight hover:bg-violet-200 text-xs font-semibold text-primary"
              @click.stop="
                store.dispatch('firebaseData/markMessageRead', {
                  message: props.message,
                })
              "
            >
              Mark as read
            </button>
            <ion-badge color="dark" class="py-1 rounded-full">{{
              duration
            }}</ion-badge>
          </div>
          <!-- <ion-button id="moreButton" size="small" color="light">
            <ion-icon :icon="ellipsisVertical"></ion-icon>
          </ion-button> -->
        </div>
      </div>

      <!-- Body -->
      <div class="flex rounded-t-2xl overflow-hidden">
        <div v-if="props.message.type === 'video'" class="aspect-square w-full">
          <VideoPlayer
            ref="mediaPlayer"
            class="h-48 w-full"
            :source="source"
            :poster="posterURL"
            fill
          ></VideoPlayer>
        </div>
        <div v-if="props.message.type === 'audio'" class="h-[58px] w-full">
          <AudioPlayer ref="mediaPlayer" :source="source" compact></AudioPlayer>
        </div>
        <div
          v-if="['link', 'text'].includes(props.message.type)"
          id="textContent"
          class="w-full bg-violet-50 text-black font-bold p-4 overflow-y-auto opacity-80"
          v-html="richify(props.message.content)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, watch, ref, computed } from "vue";
import { storage } from "@/plugins/firebase";
import { useStore } from "vuex";
import { IonBadge } from "@ionic/vue";
// import { ellipsisVertical } from "ionicons/icons";
import moment from "moment";
import AvatarGroup from "@/components/AvatarGroup";
import VideoPlayer from "@/components/VideoPlayer";
import AudioPlayer from "@/components/AudioPlayer";
import { richify } from "@/utilities/content";
import { getTimeStringFriendly } from "@/utilities/time";
import { useRoute } from "vue-router";
import { useElementVisibility } from "@vueuse/core";

const store = useStore();
const route = useRoute();
const sender = computed(() => props.message.sentBy);
const currentUser = computed(() => store.state.firebaseData.currentUser);

const mediaPlayer = ref(null);

const props = defineProps({
  message: { type: Object },
});

// Source
const source = ref();
const getSource = async () => {
  if (["video", "audio"].includes(props.message.type)) {
    // Get video file from storage
    const messageVideoRef = storage.child(props.message?.content);
    source.value = await messageVideoRef?.getDownloadURL();

    if (props.message.type === "video") {
      const posterRef = storage.child(props.message.poster);
      try {
        const signedUrl = await posterRef?.getDownloadURL();

        // set poster URL retrieved from storage
        posterURL.value = signedUrl;
      } catch (error) {
        console.error(error);
      }
    }
  }
};

const selectMessage = async () => {
  // document.getElementById(props.message.id).scrollIntoView(); // scroll into view
  if (["audio", "video", "text"].includes(props.message.type)) {
    await store.dispatch("firebaseData/selectMessage", props.message);
  } else if (props.message.type === "link") {
    store.dispatch("firebaseData/markMessageRead", { message: props.message });
    window.open(props.message.content, "_blank");
  }
};
const selectedMessage = computed(() => route.query.selectedMessageId);

// Duration
const duration = computed(() =>
  getTimeStringFriendly(props.message.contentDuration)
);

// Message selection/visibility toggle playback
const videoIsVisible = useElementVisibility(mediaPlayer);
const handleStopEvent = () => {
  mediaPlayer.value?.isPlaying && mediaPlayer.value.togglePlayback();
};
watch(videoIsVisible, () => {
  if (videoIsVisible) {
    handleStopEvent();
    // Get source if not yet retrieved
    if (!source.value) {
      getSource();
    }
  }
}); // Video scrolls out of view
watch(
  () => route.query.selectedMessageId,
  () => {
    // Auto pause
    if (route.query.selectedMessageId !== props.message?.id) {
      handleStopEvent();
    }
  }
); // Different message selected

// Unread Notification Indicator
const isUnread = computed(() => messageNotification.value?.read === false);
const messageNotification = computed(() => {
  return store.state.firebaseData.unreadMessageNotifications.find((e) => {
    return e.message.id === props.message.id;
  });
});

// Converting Video indicator
// const isConverting = computed(
//   () => props.message?.status === "needs conversion"
// );

// Poster
let posterURL = ref("");
</script>

<style>
ion-button#moreButton {
  --padding-start: 0.25rem;
  --padding-end: 0.25rem;
}
#textContent a {
  /* color: white !important; */
  text-decoration: underline;
}
</style>
