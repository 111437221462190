<template>
  <div class="w-full flex justify-center">
    <div class="max-w-screen-xl flex flex-col items-center">
      <div
        v-if="isLoading"
        class="flex flex-col w-full items-center justify-center text-black"
      >
        <ion-spinner
          name="crescent"
          class="w-16 h-16"
          color="primary"
        ></ion-spinner>
        <div class="text-3xl lg:text-4xl font-bold lg:mt-16 p-6 text-center">
          Creating group
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { sendRequest } from "@/utilities/api";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { IonSpinner } from "@ionic/vue";

const route = useRoute();
const isLoading = ref(false);

onMounted(async () => {
  isLoading.value = true;

  try {
    const response = await sendRequest(
      `/api/checkout/chat/${route.query.target}`,
      { method: "POST" }
    );

    const responseBody = await response.json();
    if (responseBody.groupUrl) {
      window.location.href = responseBody.groupUrl;
    }
  } catch (e) {
    console.error("Error", e);
  }
  isLoading.value = false;
});
</script>
