<template>
  <div
    class="flex flex-col items-center text-gray-600 bg-gradient-to-br from-violet-100 to-pink-50 py-6 px-4"
    v-if="!isLoading && !isSetupComplete"
  >
    <div class="flex flex-col gap-1 items-start">
      <div class="text-center w-full mb-3">
        <ion-badge
          class="tracking-tight bg-violet-200 text-primary font-bold -mb-1"
          >Earn with Zulu</ion-badge
        >
        <div class="text-xl font-bold text-gray-700">Setup Progress</div>
      </div>
      <!-- Pricing Plan -->
      <div class="flex gap-3">
        <div class="flex items-center">
          <ion-checkbox
            disabled
            :checked="status?.isUserSubscribed"
            aria-label="Step 1"
            class="scale-75 bg-black/20 rounded-full"
          ></ion-checkbox>
        </div>

        <div class="flex flex-col w-full">
          <div class="text-base font-medium">Select a pricing plan</div>
        </div>
      </div>
      <!-- Username -->
      <div class="flex gap-3">
        <div class="flex items-center">
          <ion-checkbox
            disabled
            :checked="status?.isUsernameComplete"
            aria-label="Step 2"
            class="scale-75 bg-black/20 rounded-full"
          ></ion-checkbox>
        </div>

        <div class="text-base font-medium">Choose a username</div>
      </div>

      <!-- Profile Page -->
      <div class="flex gap-3">
        <div class="flex items-center">
          <ion-checkbox
            disabled
            :checked="status?.isProfileComplete"
            aria-label="Step 3"
            class="scale-75 bg-black/20 rounded-full"
          ></ion-checkbox>
        </div>
        <div class="text-base font-medium">Create a profile</div>
      </div>

      <!-- Stripe Account -->
      <div class="flex gap-3">
        <div class="flex items-center">
          <ion-checkbox
            disabled
            :checked="status?.isStripeComplete"
            aria-label="Step 4"
            class="scale-75 bg-black/20 rounded-full"
          ></ion-checkbox>
        </div>
        <div class="text-base font-medium">Connect Stripe account</div>
      </div>

      <button
        class="w-full bg-violet-900/[.08] py-3 mt-5 rounded-lg text-violet-700 hover:underline font-semibold"
        @click="router.navigate({ name: 'earn' }, 'root', 'replace')"
      >
        Go to setup
      </button>
    </div>
  </div>
</template>

<script setup>
import { IonBadge, IonCheckbox } from "@ionic/vue";
import { ref, computed, onMounted } from "vue";
import { useIonRouter } from "@ionic/vue";
import { getChecklistStatus } from "@/utilities/zuluMe";
const router = useIonRouter();

// Steps
const status = ref();
const isSetupComplete = computed(
  () => status.value && Object.values(status.value)?.every((e) => e)
);
const isLoading = ref(false);
onMounted(async () => {
  isLoading.value = true;
  status.value = await getChecklistStatus();
  isLoading.value = false;
});
</script>

<style scoped>
ion-toolbar {
  --background: transparent;
}
</style>
