<template>
  <div>
    <!-- Text Message Button -->
    <button
      aria-label="text message"
      class="cursor-pointer rounded-full w-full flex bg-background-200 hover:bg-background-300 border border-solid border-gray-300 justify-start items-center text-sm px-4 py-2 gap-2 text-gray-400 hover:text-gray-500 font-bold text-lg"
      @click="setOpenCompose(true)"
    >
      Abc
    </button>
    <!-- Modal -->
    <ion-modal
      ref="composeModal"
      @ion-modal-did-dismiss="setOpenCompose(false)"
      @ionModalDidPresent="focusTextArea()"
    >
      <ion-page id="composeWindow">
        <ion-header>
          <div class="flex justify-between w-full p-4">
            <button @click="setOpenCompose(false)">
              <ion-icon
                :icon="closeOutline"
                class="text-2xl text-white backdrop-blur-lg bg-black/50 hover:bg-black/40 rounded-full p-1"
              ></ion-icon>
            </button>
            <div class="flex items-center gap-2">
              <div class="text-sm font-bold text-black">
                Estimated read time:
              </div>
              <div class="text-lg flex items-center">
                <ion-badge color="dark" class="text-lg">{{
                  estimatedDurationFriendly
                }}</ion-badge>
              </div>
            </div>
          </div>
        </ion-header>
        <ion-content>
          <div
            class="relative w-full h-full bg-black overflow-hidden flex justify-center items-center"
          >
            <div
              class="relative flex flex-col w-full h-full max-h-full bg-black"
            >
              <div
                class="absolute w-full h-full bg-black/50 flex flex-col items-center justify-center"
                v-if="isSending"
              >
                <ion-spinner
                  name="crescent"
                  color="light"
                  class="h-24 w-24"
                ></ion-spinner>
                <div class="text-white font-bold text-xl mt-4">Sending</div>
              </div>
              <div
                id="textCompose"
                class="w-full h-full rounded-b-xl bg-white px-3 overflow-y-scroll no-scrollbar"
              >
                <ion-textarea
                  aria-label="Enter Text..."
                  ref="textArea"
                  placeholder="Enter Text..."
                  class="h-full"
                  type="text"
                  :auto-grow="true"
                  v-model="content"
                ></ion-textarea>
              </div>
              <div class="w-full flex justify-end p-3 pt-4">
                <button
                  :disabled="!content.length"
                  class="rounded-lg text-white text-lg font-bold py-2 px-4 flex items-center gap-2"
                  :class="{
                    'bg-gradient-to-r from-primary to-secondary shadow-lg shadow-primary':
                      content.length,
                    'bg-gray-600 opacity-50': !content.length,
                  }"
                  @click="handleSendMessage()"
                >
                  Send
                  <ion-icon :icon="send" />
                </button>
              </div>
            </div>
          </div>
        </ion-content>
      </ion-page>
    </ion-modal>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import {
  IonSpinner,
  IonModal,
  IonContent,
  IonBadge,
  IonHeader,
  IonPage,
  IonIcon,
  IonTextarea,
} from "@ionic/vue";
import { closeOutline, send } from "ionicons/icons";
import { db } from "@/plugins/firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import { useStore } from "vuex";
import { getTimeStringFriendly, getTextTimeEstimate } from "@/utilities/time";

const store = useStore();

// Refs
const textArea = ref(null);

// Content
const content = ref("");
const estimatedDuration = computed(() => getTextTimeEstimate(content.value));
const estimatedDurationFriendly = computed(() =>
  getTimeStringFriendly(estimatedDuration.value)
);

// Send
const isSending = ref();
const handleSendMessage = async () => {
  isSending.value = true;

  try {
    const selectedGroup = store.state.firebaseData.selectedGroup;
    // Get new message doc
    const messagesCollectionRef = db
      .collection("groups")
      .doc(selectedGroup.id)
      .collection("messages");
    const newMessage = messagesCollectionRef.doc();
    console.log("content", content.value);
    // Create message
    await newMessage.set({
      content: content.value,
      group: db.collection("groups").doc(selectedGroup.id),
      contentDuration: estimatedDuration.value,
      sentAt: firebase.firestore.FieldValue.serverTimestamp(),
      sentBy: db
        .collection("users")
        .doc(store.state.firebaseData.currentUser.id), // TODO: remove hardcoded single user id
      type: "text",
    });
  } catch (error) {
    console.error(error);
  }
  isSending.value = false;
  setOpenCompose(false);
};

const focusTextArea = () => {
  console.log(textArea.value);
  // textArea.value.$el.setFocus();
};

const composeModal = ref(null);
const setOpenCompose = async (value) => {
  value
    ? composeModal.value?.$el?.present()
    : composeModal.value?.$el?.dismiss();
  if (value) {
    // Clear text
    content.value = "";
  }
};
</script>

<style>
#textCompose .textarea-legacy-wrapper {
  height: 100%;
}
</style>
