<template>
  <ion-page>
    <ion-header class="flex justify-center border-b">
      <ion-toolbar class="flex items-center lg:max-w-screen-sm bg-transparent">
        <ion-buttons slot="start">
          <ion-back-button default-href="/earn/checklist"></ion-back-button>
        </ion-buttons>
        <ion-title>Connect Stripe</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-loading :is-open="isLoading"></ion-loading>
      <div class="flex justify-center">
        <div
          class="max-w-screen-sm w-full flex flex-col p-4 items-center gap-8"
        >
          <!-- Checklist -->
          <section class="flex flex-col gap-2">
            <div class="flex flex-col items-center text-center px-4 py-3 gap-4">
              <div class="text-6xl">🏦</div>
              <div class="text-xl font-bold">Connect your bank account</div>

              <p class="font-semibold">
                Zulu uses
                <span class="font-bold text-primary italic">Stripe</span> to
                connect to your bank account
              </p>
              <p>
                We will use this to process your clients' payments, manage
                payouts, and process refunds
              </p>
            </div>
          </section>
          <ion-button class="w-full" @click="handleGoToStripe()"
            >Go to Stripe</ion-button
          >
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref } from "vue";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonBackButton,
  IonButtons,
  IonButton,
  IonLoading,
} from "@ionic/vue";
import { sendRequest } from "@/utilities/api";

const isLoading = ref(false);
const handleGoToStripe = async () => {
  isLoading.value = true;
  const response = await sendRequest("/api/integrations/stripe/setup/link");

  if (response.status === 200) {
    const responseBody = await response.text();
    window.location.href = responseBody;
  }

  isLoading.value = false;
};
</script>

<style scoped>
ion-toolbar {
  --background: transparent;
}
</style>
