<template>
  <ion-page>
    <ion-header class="flex justify-center border-b">
      <ion-toolbar class="flex items-center lg:max-w-screen-sm bg-transparent">
        <ion-title>Setup</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="flex justify-center">
        <div
          class="max-w-screen-sm w-full flex flex-col p-2 items-center gap-8"
        >
          <!-- Greeting -->
          <section>
            <div class="text-center font-extrabold lg:text-3xl text-3xl mb-2">
              Hi, {{ currentUser.firstName }} 👋
            </div>
            <div class="max-w-sm text-center font-medium">
              There's a few things you'll need to do before you can monetize
              your conversations through Zulu:
            </div>
          </section>

          <!-- Checklist -->
          <section class="flex flex-col gap-2">
            <!-- Pricing Plan -->
            <div
              class="flex rounded-xl bg-violet-50 px-4 py-3 gap-3 cursor-pointer"
              @click="
                router.navigate({ name: 'setup-billing' }, 'forward', 'push')
              "
            >
              <div class="flex items-center">
                <ion-checkbox
                  disabled
                  :checked="status?.isUserSubscribed"
                  aria-label="Step 4"
                ></ion-checkbox>
              </div>

              <div class="flex flex-col w-full">
                <div class="text-md font-bold">Select a pricing plan</div>

                <p>
                  We have multiple product plans, starting at $10/month for
                  basic features
                </p>
              </div>

              <div class="flex items-center">
                <ion-icon :icon="chevronForward"></ion-icon>
              </div>
            </div>

            <!-- Usernam -->
            <div
              class="flex rounded-xl bg-violet-50 px-4 py-3 gap-3 cursor-pointer"
              :class="{
                'pointer-events-none opacity-25 grayscale cursor-not-allowed':
                  !status?.isUserSubscribed,
              }"
              @click="
                router.navigate({ name: 'select-username' }, 'forward', 'push')
              "
            >
              <div class="flex items-center">
                <ion-checkbox
                  disabled
                  :checked="status?.isUsernameComplete"
                  aria-label="Step 1"
                ></ion-checkbox>
              </div>

              <div class="flex flex-col w-full">
                <div class="text-md font-bold">Select a username 🪪</div>

                <p>This is what will appear in the link to your sales page</p>
              </div>

              <div class="flex items-center">
                <ion-icon :icon="chevronForward"></ion-icon>
              </div>
            </div>

            <!-- Profile Page -->
            <div
              class="flex rounded-xl bg-violet-50 px-4 py-3 gap-3 cursor-pointer"
              :class="{
                'pointer-events-none opacity-25 grayscale':
                  !status?.isUserSubscribed,
              }"
              @click="
                router.navigate({ name: 'earn-create-page' }, 'forward', 'push')
              "
            >
              <div class="flex items-center">
                <ion-checkbox
                  disabled
                  :checked="status?.isProfileComplete"
                  aria-label="Step 2"
                ></ion-checkbox>
              </div>

              <div class="flex flex-col w-full">
                <div class="text-md font-bold">Create a profile 🏷️</div>

                <p class="">
                  Your profile will include your name, profile picture, bio, and
                  the package offerings you want to sell.
                </p>
              </div>
              <div class="flex items-center">
                <ion-icon :icon="chevronForward"></ion-icon>
              </div>
            </div>

            <!-- Stripe Account -->
            <div
              class="flex rounded-xl bg-violet-50 px-4 py-3 gap-3 cursor-pointer"
              :class="{
                'pointer-events-none opacity-25 grayscale':
                  !status?.isUserSubscribed,
              }"
              @click="
                router.navigate(
                  { name: 'earn-setup-stripe' },
                  'forward',
                  'push'
                )
              "
            >
              <div class="flex items-center">
                <ion-checkbox
                  disabled
                  :checked="status?.isStripeComplete"
                  aria-label="Step 3"
                ></ion-checkbox>
              </div>

              <div class="flex flex-col w-full">
                <div class="text-md font-bold">Connect a Stripe account 🏦</div>

                <p>
                  We use Stripe to collect payments and deposit money into your
                  bank account.
                </p>
              </div>

              <div class="flex items-center">
                <ion-icon :icon="chevronForward"></ion-icon>
              </div>
            </div>
          </section>

          <!-- Learn More -->
          <section>
            <div class="text-center text-sm">
              <div>Want more details about earning with Zulu?</div>
              <a
                class="font-bold"
                href="//me.zulu.video/how-earn-works"
                target="_blank"
                >Learn more</a
              >
            </div>
          </section>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonCheckbox,
  onIonViewWillEnter,
} from "@ionic/vue";
import { computed, ref } from "vue";
import { chevronForward } from "ionicons/icons";
import { useIonRouter } from "@ionic/vue";
import { useStore } from "vuex";
import { getChecklistStatus } from "@/utilities/zuluMe";
const store = useStore();
const router = useIonRouter();

const currentUser = computed(() => store.state.firebaseData.currentUser);

// Steps
const status = ref();

const isLoading = ref(false);
onIonViewWillEnter(async () => {
  isLoading.value = true;
  status.value = await getChecklistStatus();
  isLoading.value = false;
});
</script>

<style scoped>
ion-toolbar {
  --background: transparent;
}
</style>
