<template>
  <!-- Content -->
  <div class="flex justify-center w-full md:pt-8 bg-white">
    <!-- User Page -->
    <template v-if="page?.id">
      <div
        class="flex md:flex-row flex-col text-black max-w-4xl gap-4 md:gap-12 p-4"
      >
        <!-- Left Column -->
        <div class="flex flex-col gap-6 lg:w-7/12 w-full">
          <!-- User Header -->
          <div class="flex">
            <!-- Avatar -->
            <div class="w-14 h-14">
              <avatar-circle
                :user="user"
                class="shadow-lg shadow-gray-400"
              ></avatar-circle>
            </div>
            <div class="flex flex-col justify-center mx-3">
              <div class="font-bold lg:text-2xl text-lg">
                {{ user?.displayName }}
              </div>
              <a class="text-primary text-sm -mt-1"> @{{ user?.username }} </a>
            </div>
          </div>
          <!-- Video -->
          <div v-if="page.youtubeVideoID">
            <iframe
              class="overflow-hidden rounded-xl w-full aspect-video"
              :src="`https://www.youtube.com/embed/${page.youtubeVideoID}`"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <!-- Page Description -->
          <div class="" v-html="page?.description"></div>
        </div>

        <!-- Right Column -->
        <div class="lg:w-5/12 w-full">
          <div class="flex flex-col gap-4" v-if="page?.packages?.length">
            <h2 class="font-extrabold">Offerings</h2>
            <!-- Packages -->
            <div
              v-for="packageItem in page?.packages"
              :key="packageItem.id"
              class="rounded-lg shadow-xl shadow- p-4 border border-solid border-gray-300 bg-white"
            >
              <div class="font-extrabold text-lg">{{ packageItem.title }}</div>
              <div class="text-xs">
                <span class="font-bold">
                  {{ getDuration(packageItem.duration) }}
                </span>
                <span> of video messaging </span>
              </div>
              <div v-if="packageItem.description" class="my-3 text-sm">
                {{ packageItem.description }}
              </div>
              <div class="mt-4">
                <button
                  :disabled="isLoadingPurchase"
                  class="p-3 flex justify-center w-full bg-gradient-radial-at-tl from-primary to-secondary text-white font-bold rounded-lg shadow-gray-400 shadow-lg"
                  @click="selectPackage(packageItem)"
                >
                  ${{ packageItem.price }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- User not found fallback -->
    <div
      v-else
      class="text-black text-3xl flex justify-center lg:pt-4 h-screen"
    >
      <div class="p-12 lg:rounded-t-xl w-full h-full">
        <div class="flex flex-col items-center">
          <div class="opacity-25">No page found for</div>
          <div class="italic font-bold opacity-50 my-4">
            @{{ route.params.username }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import AvatarCircle from "../../components/AvatarCircle";

const store = useStore();
const route = useRoute();

const page = computed(() => store.state.profile.page);
const user = computed(() => store.state.profile.user);

const isLoadingPurchase = ref(false);
const selectPackage = (packageItem) => {
  window.location.href = `/checkout/register?packageId=${packageItem.id}`;
};

// Duration
const getDuration = (packageDuration) => {
  let duration;
  let hours, minutes;
  hours = Math.floor(packageDuration / 1000 / 60 / 60);
  minutes = Math.floor((packageDuration / 1000 / 60 / 60 - hours) * 60);

  console.log("hours", hours);

  const durationStringBuilder = [];
  // hours
  if (hours) {
    durationStringBuilder.push(hours.toString());
    durationStringBuilder.push("hour");
  }

  // minutes
  if (minutes) {
    durationStringBuilder.push(minutes.toString());
    durationStringBuilder.push("min");
  }

  duration = durationStringBuilder.join(" ");

  console.log("test", duration);

  return duration;
};
</script>

<style>
#packagesModal::part(content) {
  @apply md:w-[300px] md:max-h-[500px] md:h-[400px];
}
</style>
